.slide {

    .marginals {
        text-align: right;
        position: absolute;
        right: @slide-padding-right;
        background-color: transparent; //reset
        letter-spacing: -1px;

        &:after {
            content: '';
            width: 100px;
            height: 50px;
            display: inline-block;
            background: transparent url('@{theme-image-path}/logo_fiba_border.png') no-repeat 100% 50%;
            background-size: contain;
            vertical-align: top;
        }

        .marginals-logo,
        .marginals-chapter {
            display: none;
        }

        .marginals-slide {
            font-family: @fiba;
            font-size: 18px;
            position: fixed;
            bottom: 34px;
            right: @slide-padding-right;
            padding: 5px;
            background: black;
            color: white;
        }

        &.header {
            background-color: transparent; //reset
            top: 60px;
        }

        &.footer {
            background-color: transparent; //reset
            bottom: 65px;
        }
    }
}

.slide-footnote-wrapper .slide-footnote-logo {
    margin-bottom: 10px;
}

.editor-content .component-list {
    min-height: 17px;
}

@base-print-color: 13px;

html.print-view {

    @media screen and (min-width:  @compat-screen-sm) and (max-width:  @compat-screen-xl) {
        &,
        & body {
            font-size: @base-print-color;
        }
    }
}
