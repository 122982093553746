/* =============================================================================
    THEME: overrides
   ========================================================================== */
// Backward compatibility
.component,
.marginals {

    p {
        font-size: inherit !important;
    }
}

.slide-footnote-wrapper {
    bottom: 5rem;
}

.slide {

    .marginals {
        font-size: 1rem;

        &.footer {
            bottom: 2.5rem;
        }

        &-slide span {
            padding: 0 3px;
        }

        &-slide-number:after {
            padding-left: 14px;
        }
    }
}
