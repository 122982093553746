.component {

    &-textblock {

        blockquote  {
            font-size: 20px;
            line-height: 28px;
            font-weight: normal;
        }
    }
}
