.component-textblock {
    a {
        &.slide-popup-link {
            &:before {
                content: " ";
                background: transparent url('../images/themes/fei/popup-arrow.png') no-repeat center;
                background-size: cover;
                vertical-align: middle;
                height: 33px;
                width: 33px;
                margin-top: -0.1em;
                margin-right: 0.5em;
                display: inline-block;
            }
        }
    }
}

.component-bullets {

    > ul {

        > li {
            font-family: @bliss;
            font-size: 1.56rem;
            font-weight: 300;
            margin-bottom: 1.5rem;

            > .component-list {
                margin-top: 0.35rem;
            }
        }

        &.circles {
            font-weight: normal;

            > li:before {
                top: 7px;
                padding: 3px;
            }
        }

        &.numeric > li {
            font-weight: bold;

            > span, > div, p {
                font-weight: normal;
            }
        }

        &.dash {
            @dash-width: 10px;
            @dash-height: 2px;
            @dash-indent: 25px;

            padding-left: @dash-indent;

            > li:before {
                position: absolute;
                top: @theme-font-size-base / 2 - @dash-height;
                left: -@dash-indent;
                content: '';
                display: inline-block;
                width: @dash-width;
                height: @dash-height;
                background-color: @theme-color-purple;
            }
        }
    }
}

@bkgs: bkg-1, bkg-2, bkg-3, bkg-4, bkg-5, bkg-6, bkg-7, bkg-8, bkg-9, bkg-10, bkg-11,;

.background-overlays(@bkgs; @index) when (@index > 0) {
    .background-overlays(@bkgs; (@index - 1));

    @bkg: extract(@bkgs, @index);

    .@{bkg}:after {
        background-image: url('../images/themes/@{theme}/@{bkg}.png');
    }
}

.component-bg-targeted-image,
.component-video-player {

    [class*=bkg-] {
        padding: 0;
        position: static;

        @bkg: extract(@bkgs, 1);

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            min-width: 100%;
            min-height: 100%;
            background-image: url('../images/themes/@{theme}/@{bkg}.png');
            background-position: right top;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .background-overlays(@bkgs, length(@bkgs));

}

.component-high-map {

    .map-marker {

        &.with-label {

            .marker-content {
                font-family: @bliss;
            }
        }
    }
}

.component-timeline {

    font-family: @bliss;
}

.slide-popup {

    + .modal-backdrop {
        background-color: @theme-color-purple;
        opacity: 0.7;
    }

    .modal-body {
        border-top: 0;

        .close {
            outline: 0;

            span {
                text-indent: -9999px;
                display: inline-block;
            }

            &:after {
                content: " ";
                background: transparent url('../images/themes/@{theme}/close-btn.png') no-repeat center;
                background-size: cover;
                display: inline-block;
                width: 33px;
                height: 33px;
            }
        }
    }
}
