/* =============================================================================
    Web fonts
    ========================================================================== */
@prelo-slab: 'PreloSlab', arial, sans-serif;

/*
/* Prelo Slab
****/
@font-face {
  font-family: 'PreloSlab';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/football-league/PreloSlab-Book.eot');
  src: url('../fonts/football-league/PreloSlab-Book.eot?#iefix') format('embedded-opentype'),
  url('../fonts/football-league/PreloSlab-Book.woff2') format('woff2'),
  url('../fonts/football-league/PreloSlab-Book.woff') format('woff'),
  url('../fonts/football-league/PreloSlab-Book.ttf') format('truetype'),
  url('../fonts/football-league/PreloSlab-Book.svg#prelo_slab_black') format('svg');
}
@font-face {
  font-family: 'PreloSlab';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/football-league/PreloSlab-Book.eot');
  src: url('../fonts/football-league/PreloSlab-Book.eot?#iefix') format('embedded-opentype'),
  url('../fonts/football-league/PreloSlab-Book.woff2') format('woff2'),
  url('../fonts/football-league/PreloSlab-Book.woff') format('woff'),
  url('../fonts/football-league/PreloSlab-Book.ttf') format('truetype'),
  url('../fonts/football-league/PreloSlab-Book.svg#prelo_slab_black') format('svg');
}
@font-face {
  font-family: 'PreloSlab';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/football-league/PreloSlab-Bold.eot');
  src: url('../fonts/football-league/PreloSlab-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/football-league/PreloSlab-Bold.woff2') format('woff2'),
  url('../fonts/football-league/PreloSlab-Bold.woff') format('woff'),
  url('../fonts/football-league/PreloSlab-Bold.ttf') format('truetype'),
  url('../fonts/football-league/PreloSlab-Bold.svg#prelo_slab_bold') format('svg');
}
@font-face {
  font-family: 'PreloSlab';
  font-weight: bold;
  font-style: normal;
  src: url('../fonts/football-league/PreloSlab-Bold.eot');
  src: url('../fonts/football-league/PreloSlab-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/football-league/PreloSlab-Bold.woff2') format('woff2'),
  url('../fonts/football-league/PreloSlab-Bold.woff') format('woff'),
  url('../fonts/football-league/PreloSlab-Bold.ttf') format('truetype'),
  url('../fonts/football-league/PreloSlab-Bold.svg#prelo_slab_bold') format('svg');
}
@font-face {
  font-family: 'PreloSlab';
  font-weight: 900;
  font-style: normal;
  src: url('../fonts/football-league/PreloSlab-Black.eot');
  src: url('../fonts/football-league/PreloSlab-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/football-league/PreloSlab-Black.woff2') format('woff2'),
  url('../fonts/football-league/PreloSlab-Black.woff') format('woff'),
  url('../fonts/football-league/PreloSlab-Black.ttf') format('truetype'),
  url('../fonts/football-league/PreloSlab-Black.svg#prelo_slab_black') format('svg');
}
