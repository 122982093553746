/* =============================================================================
    THEME: Component
    ========================================================================== */
.component {

    &-textblock {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            line-height: 1.2;
        }

        h1 {
           margin-bottom: 1.8rem;
           text-transform: uppercase;
        }

        h2 {
            margin-bottom: 1.2rem;
            font-family: @lineto-circular;
        }

        p {
            margin-bottom: 1rem;
        }
    }

    &-bullets {

        > ul {
            margin-top: 1.6em;

            &.circles {

                > li:before {
                    background-color: @color-black;
                    top: 8px;
                }
            }
        }
    }

    &-chart {
        tspan,
        text {
            text-transform: uppercase;
            font-family: @raleway;
            font-size: 14px !important;
        }
    }

    &-table {

        .table {

            font-size: @compat-font-size-lg;

            .highlight {
                background-color: @theme-color-cyan !important;
            }

            tbody {

                tr {

                    border-bottom: 10px solid #FFFFFF;

                    &:nth-child(odd) {
                        background-color: lighten(@theme-color-cyan, 45%);
                    }

                    &:nth-child(even) {
                        background-color: lighten(@theme-color-cyan, 50%);
                    }

                }
            }
        }
    }

    &-comp-panel {

        .block {

            position: relative;

            &-skew {

                &:after {
                    content: "";
                    position: absolute;
                    top: -90px;
                    right: -680px;
                    width: 1082px;
                    height: 1500px;
                    z-index: -5;
                    background: url('../images/themes/fresqo/skew-border.png') 0 0 no-repeat;
                }
            }
        }
    }
}

.txt-light {

    .component {

        &-bullets {

            > ul {

                &.circles {

                    > li:before {
                        background-color: @color-white;
                    }
                }
            }
        }
    }
}

.slide-footnote {

    &-wrapper {

        bottom: 60px;

        .slide-footnote {

            &:before {
                content: none;
            }
        }
    }
}
