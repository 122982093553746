.component-bg-image, .component-bg-targeted-image {

    .background {
        display: block;
        height: 100%;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat !important;
    }

    .print-background {
        display: none;
        height: 100%;
    }

    .left {
        &-top img {
            left: 0;
            top: 0;
            .transform(translate(0, 0));
        }

        &-middle img {
            left: 0;
            top: 50%;
            .transform(translate(0, -50%));
        }

        &-bottom img {
            left: 0;
            top: auto;
            bottom: 0;
            .transform(translate(0, 0));
        }
    }

    .center {
        &-top img {
            left: 50%;
            top: 0;
            .transform(translate(-50%, 0));
        }

        &-middle img {
            left: 50%;
            top: 50%;
            .transform(translate(-50%, -50%));
        }

        &-bottom img {
            left: 50%;
            top: auto;
            bottom: 0;
            .transform(translate(-50%, 0));
        }
    }

    .right {
        &-top img {
            left: auto;
            right: 0;
            top: 0;
            .transform(translate(0, 0));
        }

        &-middle img {
            left: auto;
            right: 0;
            top: 50%;
            .transform(translate(0, -50%));
        }

        &-bottom img {
            left: auto;
            right: 0;
            top: auto;
            bottom: 0;
            .transform(translate(0, 0));
        }
    }
}
