.linear-gradient-slide(@color: #000000, @position: right, @position-webkit: left) {
    background-image: -webkit-linear-gradient(@position-webkit, color-stop(@color 0%), color-stop(rgba(0, 0, 0, 0) 100%));
    background-image: -moz-linear-gradient(@position-webkit, color-stop(@color 0%), color-stop(rgba(0, 0, 0, 0) 100%));
    background-image: -ms-linear-gradient(to @position, @color 0%, rgba(0, 0, 0, 0) 100%);
    background-image: -o-linear-gradient(@position-webkit, color-stop(@color 0%), color-stop(rgba(0, 0, 0, 0) 100%));
    background-image: linear-gradient(to @position, @color 0%, rgba(0, 0, 0, 0) 100%);
    background-repeat: repeat-x;

    // Fix for IE9 gradient positions
    & when (@position = left) {
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#ff000000', GradientType=1);
    }

    & when (@position = right) {
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff000000', endColorstr='#00000000', GradientType=1);
    }

    & when (@position = top) {
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#ff000000', GradientType=0);
    }

    & when (@position = bottom) {
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff000000', endColorstr='#00000000', GradientType=0);
    }
}

.overlay-gradient {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &.left {
        right: auto;
        .linear-gradient-slide(@theme-gradient-color, right, left);
    }

    &.right {
        left: auto;
        .linear-gradient-slide(@theme-gradient-color, left, right);
    }

    &.top {
        bottom: auto;
        .linear-gradient-slide(@theme-gradient-color, bottom, top);
    }

    &.bottom {
        top: auto;
        .linear-gradient-slide(@theme-gradient-color, top, bottom);
    }
}
