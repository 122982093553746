.component-bullets > ul.circles > li:before {
    top: 5px;
}

.slide .marginals {
    font-size: @textblock-font-size-paragraph;
    opacity: .5;
}

.slide .marginals.footer {
    bottom: 65px;
}

.marginals-chapter {
    display: inline-block;
    position: relative;

    &:after {
        content: '';
        width: 65%;
        height: 3px;
        display: block;
        position: absolute;
        top: 30px;
        left: 0;
        background-color: @theme-color-light;
    }
}

.txt-dark .marginals-chapter:after {
    background-color: @theme-color-dark;
}
