#importer-box {
    position: relative;
    border: 3px dashed red;
    padding: 10px;
    height: 50px;
    overflow: hidden;

    &:before {
        content: 'Paste table here...';
        position: absolute;
        left: 50%;
        top: 50%;
        text-transform: uppercase;
        color: darken(lightgray, 10%);

        .transform(translateY(-50%),translateX(-50%));
    }

    &:focus {
        outline: 0;
        background: lightgray;
    }

    table  {
        visibility: hidden;
    }

}
