@fiba: 'Fiba', arial, sans-serif;
@univers-LT: 'Univers LT', arial, sans-serif;

/*
/* Fiba
****/
@font-face {
  font-family: 'Fiba';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/fiba/Fiba-Regular.eot');
  src: url('../fonts/fiba/Fiba-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fiba/Fiba-Regular.woff2') format('woff2'),
  url('../fonts/fiba/Fiba-Regular.woff') format('woff'),
  url('../fonts/fiba/Fiba-Regular.ttf') format('truetype'),
  url('../fonts/fiba/Fiba-Regular.svg#fiba_regular') format('svg');
}
@font-face {
  font-family: 'Fiba';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/fiba/Fiba-Regular.eot');
  src: url('../fonts/fiba/Fiba-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fiba/Fiba-Regular.woff2') format('woff2'),
  url('../fonts/fiba/Fiba-Regular.woff') format('woff'),
  url('../fonts/fiba/Fiba-Regular.ttf') format('truetype'),
  url('../fonts/fiba/Fiba-Regular.svg#fiba_regular') format('svg');
}
@font-face {
  font-family: 'Fiba';
  font-weight: 500;
  font-style: normal;
  src: url('../fonts/fiba/Fiba-Regular.eot');
  src: url('../fonts/fiba/Fiba-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fiba/Fiba-Regular.woff2') format('woff2'),
  url('../fonts/fiba/Fiba-Regular.woff') format('woff'),
  url('../fonts/fiba/Fiba-Regular.ttf') format('truetype'),
  url('../fonts/fiba/Fiba-Regular.svg#fiba_regular') format('svg');
}
@font-face {
  font-family: 'Fiba';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/fiba/Fiba-Regular.eot');
  src: url('../fonts/fiba/Fiba-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fiba/Fiba-Regular.woff2') format('woff2'),
  url('../fonts/fiba/Fiba-Regular.woff') format('woff'),
  url('../fonts/fiba/Fiba-Regular.ttf') format('truetype'),
  url('../fonts/fiba/Fiba-Regular.svg#fiba_regular') format('svg');
}
@font-face {
  font-family: 'Fiba';
  font-weight: bold;
  font-style: normal;
  src: url('../fonts/fiba/Fiba-Regular.eot');
  src: url('../fonts/fiba/Fiba-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fiba/Fiba-Regular.woff2') format('woff2'),
  url('../fonts/fiba/Fiba-Regular.woff') format('woff'),
  url('../fonts/fiba/Fiba-Regular.ttf') format('truetype'),
  url('../fonts/fiba/Fiba-Regular.svg#fiba_regular') format('svg');
}

/*
/* Univers LT
****/
@font-face {
  font-family: 'Univers LT';
  font-weight: 300;
  font-style: normal;
  src: url('../fonts/fiba/UniversLT-Light.eot');
  src: url('../fonts/fiba/UniversLT-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fiba/UniversLT-Light.woff2') format('woff2'),
  url('../fonts/fiba/UniversLT-Light.woff') format('woff'),
  url('../fonts/fiba/UniversLT-Light.ttf') format('truetype'),
  url('../fonts/fiba/UniversLT-Light.svg#univers-lt_light') format('svg');
}
@font-face {
  font-family: 'Univers LT';
  font-weight: 400;
  font-style: normal;
  src: url('../fonts/fiba/UniversLT-Regular.eot');
  src: url('../fonts/fiba/UniversLT-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fiba/UniversLT-Regular.woff2') format('woff2'),
  url('../fonts/fiba/UniversLT-Regular.woff') format('woff'),
  url('../fonts/fiba/UniversLT-Regular.ttf') format('truetype'),
  url('../fonts/fiba/UniversLT-Regular.svg#univers-lt_regular') format('svg');
}
@font-face {
  font-family: 'Univers LT';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/fiba/UniversLT-Regular.eot');
  src: url('../fonts/fiba/UniversLT-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fiba/UniversLT-Regular.woff2') format('woff2'),
  url('../fonts/fiba/UniversLT-Regular.woff') format('woff'),
  url('../fonts/fiba/UniversLT-Regular.ttf') format('truetype'),
  url('../fonts/fiba/UniversLT-Regular.svg#univers-lt_regular') format('svg');
}
@font-face {
  font-family: 'Univers LT';
  font-weight: 700;
  font-style: normal;
  src: url('../fonts/fiba/UniversLT-Bold.eot');
  src: url('../fonts/fiba/UniversLT-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fiba/UniversLT-Bold.woff2') format('woff2'),
  url('../fonts/fiba/UniversLT-Bold.woff') format('woff'),
  url('../fonts/fiba/UniversLT-Bold.ttf') format('truetype'),
  url('../fonts/fiba/UniversLT-Bold.svg#univers-lt_bold') format('svg');
}
@font-face {
  font-family: 'Univers LT';
  font-weight: bold;
  font-style: normal;
  src: url('../fonts/fiba/UniversLT-Bold.eot');
  src: url('../fonts/fiba/UniversLT-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fiba/UniversLT-Bold.woff2') format('woff2'),
  url('../fonts/fiba/UniversLT-Bold.woff') format('woff'),
  url('../fonts/fiba/UniversLT-Bold.ttf') format('truetype'),
  url('../fonts/fiba/UniversLT-Bold.svg#univers-lt_bold') format('svg');
}
@font-face {
  font-family: 'Univers LT';
  font-weight: 800;
  font-style: normal;
  src: url('../fonts/fiba/UniversLT-Black.eot');
  src: url('../fonts/fiba/UniversLT-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/fiba/UniversLT-Black.woff2') format('woff2'),
  url('../fonts/fiba/UniversLT-Black.woff') format('woff'),
  url('../fonts/fiba/UniversLT-Black.ttf') format('truetype'),
  url('../fonts/fiba/UniversLT-Black.svg#univers-lt_black') format('svg');
}
