.slide {

    .marginals {
        font-family: @font-randa-text;
        font-size: 0.6875rem;

        &.header {
            top: 25px;
        }

        .marginals-slide-number,
        .marginals-chapter {
            display: none;
        }

        .marginals-presentation {
            font-weight: bold;
            display: block;
        }

        .marginals-date {
            display: block;
            margin: 0;

            &:before {
                content: '';
                display: none;
            }
        }

        .marginals-slide {
            height: 0;
            overflow: hidden;
            display: block;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 85px;
                height: 85px;
                display: inline-block;
                background: transparent url('../images/themes/@{theme}/logo.png') no-repeat 100% 50%;
                background-size: contain;
            }
        }
    }

    &.txt-dark {

        .marginals .marginals-slide {

            &:before {
                background-image: url('../images/themes/@{theme}/logo_blue.png');
            }
        }
    }
}
