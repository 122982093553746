@font-helvetica: helvetica, arial, sans-serif;
@font-randa-headline: 'The Open headline', helvetica, arial, sans-serif;
@font-randa-text: 'The Open Text', helvetica, arial, sans-serif;

@font-face {
    .font-face ('The Open headline', 'the-open-1860-headline-regular');
}
@font-face {
    .font-face ('The Open headline', 'the-open-1860-headline-bold', @weight: bold);
}
@font-face {
    .font-face ('The Open headline', 'the-open-1860-headline-semi-bold', @weight: 300);
}

@font-face {
    .font-face ('The Open Text', 'the-open-1860-text-regular');
}
@font-face {
    .font-face ('The Open Text', 'the-open-1860-text-regular-italic', @style: italic);
}
@font-face {
    .font-face ('The Open Text', 'the-open-1860-text-bold', @weight: bold);
}
@font-face {
    .font-face ('The Open Text', 'the-open-1860-text-bold-italic', @weight: bold, @style: italic);
}
