
.slide .marginals {
    font-size: 11px;
    line-height: 14px;

    &.header {
        top: 30px;
    }

    &-date {
        margin-left: 380px;
    }

    &-presentation {
        display: inline-block;
    }

    &-chapter {
        display: none;
    }

    &-slide {
        height: 0;
        overflow: hidden;
        display: block;

        &:before {
            content: '';
            position: absolute;
            top: -30px;
            right: 0;
            width: 80px;
            height: 117px;
            display: inline-block;
            background: transparent url('../images/themes/@{theme}/badge.jpg') no-repeat;
            background-size: contain;
        }
    }
}
