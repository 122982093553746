.component-column {
    @gutter: @grid-gutter-width / 2;

    // Centre single column
    .single-col {
        margin: 0 auto;
    }

    > .row {
        margin-left: -@gutter;
        margin-right: -@gutter;
    }

    .column {
        padding-left: @gutter;
        padding-right: @gutter;
    }
}
