
.slide {

    &:before {
        content: '';
        background-image: url('../images/themes/@{theme}/logo-colour.png');
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        position: absolute;
        width: 80px;
        height: 25px;
        top: 3%;
        left: 5rem;
        z-index: @theme-zindex-overlay + 1;
    }

    &.txt-light:before {
        background-image: url('../images/themes/@{theme}/logo-white.png');
    }
}

.marginals {

    &.header {
        top: 40px;
    }

    &-slide {
        margin-right: -3rem;
    }

    &-chapter {
        display: none;
    }
}
