
.component-comp-panel {

    [class*="circle-"] {

         > .panel-wrapper {
            position: relative;
            padding: 50%;
            border-radius: 50%;

            > div,
            > img {
                position: absolute;
                top: 50%;
                left: 50%;
                .transform(translateX(-50%) translateY(-50%));
            }
        }
    }

    .panel-wrapper {
        padding: 20px;
    }

    .panel-circle {
        padding: 0;

        .panel-wrapper {
            position: relative;
            padding: 50%;
            border-radius: 50%;

            > .component-list {
                width: 90%;
                position: absolute;
                top: 50%;
                left: 50%;
                .transform(translateX(-50%) translateY(-50%));

                &.selected {
                    z-index: 1;
                }
            }
        }
    }

    // Component options styling
    &-options-bgcolor {
        float: left;
        display: inline-block;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        margin: 5px;
        margin-left: -5px;
    }

    &-height-label {
        padding: 5px;
        line-height: 35px;
    }

    .comp-panel-color-btn {
        border-right-style: solid;
        border-right-width: 35px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
