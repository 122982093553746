.component-table {
    td, th {
        &.highlight {
            @light: lighten(@table-highlight-color-light, 40%);
            color: contrast(@light, @theme-color-dark, @theme-color-light);
            background-color: @light;
        }
    }
}


.txt-dark .component-table {
    td, th {
        &.highlight {
            @dark: lighten(@table-highlight-color-dark, 40%);
            color: contrast(@dark, @theme-color-dark, @theme-color-light);
            background-color: @dark;
        }
    }
}
