.component-comp-panel {

    .panel-wrapper {
        padding: 0;
    }

    .gradient-radial {
        #gradient.radial-three-colors(rgba(0,0,0, 0.75) 20%; rgba(0,0,0, 0.5) 60%; rgba(0,0,0, 0) 85%);
        padding: 20px 60px;
    }

    .presentation-cover {
        border-top: 6px solid @color-white;
        border-bottom: 6px solid @color-white;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 100px;
        margin-bottom: 50px !important;

        h1 {
            font-size: unit(52px/@compat-font-size-base, rem);
            font-weight: 800;
            text-transform: uppercase;
            padding: 2rem 0;
        }
    }

    .chapter-cover {
        #gradient.radial-three-colors(rgba(0,0,0, 0.75) 20%; rgba(0,0,0, 0.5) 60%; rgba(0,0,0, 0) 85%);
        //border-top: 6px solid @color-white;
        border-bottom: 6px solid @color-white;
        padding: 20px 60px;
        letter-spacing: 4px;
        display: inline-block;
        width: 100%;

        &:before, &:after {
            content: "";
            width: 33%;
            //display: inline-block;
            background-color: @color-white;
            height: 6px;
            position: absolute;
            top: 0;
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }

        h1 {
            font-size: unit(67px/@compat-font-size-base, rem);
            font-weight: 800;
            text-transform: uppercase;
            margin-top: unit(50px/@compat-font-size-base, rem);
            margin-bottom: unit(50px/@compat-font-size-base, rem) !important;
        }
    }

    .corner-braces {
        @corner-size: 200px;

        #gradient.radial-three-colors(rgba(0,0,0, 0.75) 50%; rgba(0,0,0, 0.6) 75%; rgba(0,0,0, 0.25) 100%);
        padding: 10px 30px;
        position: relative;

        &:before,
        &:after {
          content: "";
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          width: 150px;
          height: 150px;
          background: transparent no-repeat top left;
          background-size: contain;
          background-image: data-uri('image/svg+xml;base64', '@{svg-path}tFL_cup-brackets.svg');
        }

        &:after {
          top: auto;
          left: auto;
          bottom: 0;
          right: 0;
           .rotate(180deg);//BOOTSTRAP
        }
    }

    .shaded {
        background: rgba(0, 0, 0, 0.5);
        padding: 18px;
        margin-bottom: 30px;
    }
}

.component-high-map {

    .map-marker {

        &.with-label {

            .marker-content {
                font-family: @prelo-slab;
            }
        }
    }
}

.modal-content {
    label {
        font-weight: bold;
    }
}
