.slide .marginals {

    font-size: 15px;
    line-height: 1.6;
    text-transform: uppercase;

    &-date {
        margin-left: 49%;
    }

    &.footer {
        bottom: 50px;
    }

    &-chapter {
        position: relative;

        &-number {
            display: none;
        }

        &-name {
            height: 0;
            overflow: hidden;
            display: block;

            &:before {
                width: 175px;
                content: 'Red Bull';
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    &-slide {
        height: 0;
        overflow: hidden;
        display: block;

        &:before {
            content: '';
            position: absolute;
            top: -10px;
            right: 0;
            width: 140px;
            height: 48px;
            display: inline-block;
            background: transparent url('../images/themes/@{theme}/redbull-logo-light.png') no-repeat;
            background-size: contain;
        }
    }
}

.txt-dark {
    &.slide .marginals {
        &-slide {
            &:before {
                background-image: url('../images/themes/@{theme}/redbull-logo.png');
            }
        }
    }
}
