.txt-light {
    color: @theme-color-light;
}

.txt-dark {
    color: @theme-color-dark;
}

body {
    &.admin-editor-presentation-chapter-slide,
    &.admin-editor-slide {
        background-color: @theme-letterbox-color;
    }
}

.slide {
    font-family: @theme-font-family;
    font-weight: @theme-font-weight;
    font-size:  @theme-font-size;

    line-height: @theme-line-height;

    .editor-deselector {
        padding: @slide-padding-top @slide-padding-right @slide-padding-bottom @slide-padding-left;
    }
}

.editor-front-layer {
    top: @slide-padding-top;
    right: @slide-padding-right;
    left: @slide-padding-left;
    bottom: @slide-padding-bottom;
}

.editor-back-layer {

    &.disabled-controls:after {
        border: 7px solid @theme-letterbox-color;
    }
}

.sidemenu-border,
.sidemenu-border-primary {
    background-color: @client-brand-primary;
}

.sidemenu-border-secondary {
    background-color: @sidebar-second-color;
}

.slide-footnote {

    &-wrapper {
        bottom: @slide-padding-bottom/2;
        padding-left: @slide-padding-left;
        padding-right: @slide-padding-right;
    }
}

.slide .marginals {
    left: @slide-padding-left;
    right: @slide-padding-right;

    &.header {
        top: @slide-padding-top/4;
    }

    &.footer {
        bottom: @slide-padding-top/4;
    }

    &-slide {

        &-number::after {
            content: @marginals-info-separator;
        }
    }
}

.print-page {

    .slide-container {

        font-family: @theme-font-family;
        font-weight: @theme-font-weight;
        line-height: @theme-line-height;
        font-size:  @theme-font-size;

        .marginals {
            font-family: @theme-font-family;

            left: @slide-padding-left;
            right: @slide-padding-right;
        
            &.header {
                top: @slide-padding-top/4;
            }
        
            &-slide {
        
                &-number::after {
                    content: @marginals-info-separator;
                }
            }
        }

        .slide-footnote-wrapper {
            bottom: @slide-padding-bottom/2;
        }
    }
}
