.marginals {
    font-family: @prelo;

    .print-page & {
        display: none !important;
    }
}

.editor-front-layer {
    bottom: 80px;
    top: 80px;
}

.component-bullets {

    > ul {
        font-size: @textblock-font-size-paragraph;
        font-family: @textblock-font-family-paragraph;
        font-style: @textblock-font-style-paragraph;
        font-weight: @textblock-font-weight-paragraph;
        line-height: @textblock-line-height-paragraph;
        color: @textblock-color-paragraph;
        letter-spacing: @textblock-letter-spacing-paragraph;
        text-transform: @textblock-text-transform-paragraph;
        margin-bottom: @textblock-margin-bottom-paragraph;
    }
}

.list-circles {
    & > li:before {
        top: 10px;
    }
}

.component-high-map {

    .map-marker {

        &.with-label {

            .marker-content {
                border: 1px solid @theme-color-gold;
                background-color: @theme-color-gold;
                box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
                border-radius: 3px;
                color: @color-white;
                font-family: @prelo;
            }
        }
    }
}

.component-chart {

    .highcharts-container {
        font-family: @prelo !important;

        svg {
            font-family: @prelo !important;
        }
    }
}
