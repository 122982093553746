
.txt-dark .component-table table {

    tr:nth-child(even) td {
        background-color: fade(black, 5%);
    }

    td,
    th {
        color: inherit;
        border-color: @theme-color-dark;

        &.highlight {
            color: inherit;
            background-color: fade(@theme-color-cyan, 20%) !important
        }
    }
}

.component-table {

    table {
        border: 0;

        thead,
        tbody,
        tfoot {

            tr:nth-child(even) td {
                background-color: fade(white, 8%);
            }

            td,
            th {
                padding: 20px 15px;
                border: 0;
                border-bottom: 1px solid @theme-color-light;
                font-size: unit(18px/@theme-font-size-base, rem);
                color: @theme-color-light;

                &.highlight {
                    color: @theme-color-light;
                    background-color: fade(@theme-color-light, 20%) !important;
                }
            }
        }

        thead {
            th {
                border-width: 2px;
            }
        }
    }
}

.component-bullets {

    > ul.circles > li:before {
        top: 0.095rem;
    }

    > ul.numeric > li{
        list-style: decimal;
        font-weight: bold;

        > div {
            font-weight: normal;
        }

        &:before {
            display: none;
        }
    }
}

.component-high-map {

    .theme {

        &-blue {
            &.with-pin {
                border: 2px solid @theme-color-cyan;
                background-color: @color-white;
            }
        }
    }
}

.video-controls {
    bottom: 7%;
}

.component-floating-box {
    border: 1px dashed @theme-color-cyan;
}

.component-column {
    margin-bottom: 20px;

    &.component-list-last {
        margin-bottom: 0;
    }
}

.component-textblock {

    a {
        color: @theme-color-blue;
        text-decoration: underline;

        &:hover,
        &:focus {
            color: lighten(@theme-color-blue, 10%);
            text-decoration: underline;

        }
    }
}

// CFG backward compatibility
.circle-blue {
    padding: 0;

    .panel-wrapper {
        background: fade(@theme-color-cyan, 80%);
        color: contrast( fade(@theme-color-cyan, 80%) );

        position: relative;
        padding: 50%;
        border-radius: 50%;

        > .component-list {
            width: 90%;
            position: absolute;
            top: 50%;
            left: 50%;
            .transform(translateX(-50%) translateY(-50%));

            // Using css transform resets zindex
            // Keep zindex higher to avoid component options being overlaid by other panel
            &.selected {
                z-index: 1;
            }
        }
    }
}

// CFG backward compatibility
.block-blue {
    background: @theme-color-cyan;
    color: contrast(@theme-color-cyan);
    padding: 5px;
}