@bullet-radius: 5px;
@bullet-indent: 22px;
@bullet-background-color: @color-primary;

@bullet-font-size: @textblock-font-size-paragraph;
@bullet-font-family: @theme-font-family;
@bullet-font-style: @textblock-font-style-paragraph;
@bullet-font-weight: @textblock-font-weight-paragraph;
@bullet-line-height: @textblock-line-height-paragraph;
@bullet-letter-spacing: @textblock-letter-spacing-paragraph;
@bullet-color: @textblock-color-paragraph;
@bullet-text-transform: @textblock-text-transform-paragraph;

@bullet-nested-margin-top: 0;
@bullet-margin-bottom: @textblock-margin-bottom-paragraph;

@bullet-numeric-color: inherit;

.list-rtl(@rtl: false) {

    & when (@rtl = true) {
        padding-right: @bullet-indent;
        padding-left: 0;

        > li:before {
            right: -@bullet-indent;
            left: auto;
        }
    }

    & when (@rtl = false) {
        padding-left: @bullet-indent;

        > li:before {
            left: -@bullet-indent;
        }
    }
}

.list-circles() {

    > li:before {
        position: absolute;
        top: (@bullet-font-size - @bullet-radius * 2) / 2;
        content: '';
        display: inline-block;
        border-radius: 50%;
        padding: @bullet-radius;
        background-color: @bullet-background-color;
    }

    ul {
        margin-top: @bullet-nested-margin-top;
    }

    .list-rtl();
}

.list-numeric() {
    counter-reset: bullets;

    > li:before {
        counter-increment: bullets;
        content: counter(bullets) ". ";
        position: absolute;
        top: 0;
        display: inline-block;
        color: @bullet-numeric-color;
    }

    ul {
        margin-top: @bullet-nested-margin-top;
    }

    .list-rtl();
}

.list-square() {
    .list-circles();

    > li:before {
        border-radius: 0;
        padding: 3px;
    }
}

.component-bullets {

    > ul {
        padding: 0;

        > li {
            list-style: none;
            position: relative;
            font-family: @bullet-font-family;
            font-size: @bullet-font-size;
            font-weight: @bullet-font-weight;
            line-height: @bullet-line-height;
            font-style: @bullet-font-style;
            letter-spacing: @bullet-letter-spacing;
            text-transform: @bullet-text-transform;
            margin-bottom: @bullet-margin-bottom;
        }

        &.circles {
            .list-circles();
        }

        &.numeric {
            .list-numeric();
        }

        &.square {
            .list-square();
        }
    }

    &.component-direction-rtl {

        > .circles,
        > .numeric,
        > .square {
            .list-rtl(true);
        }
    }
}
