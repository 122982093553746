.transform(@transform...) {
    -webkit-transform: @transform;
        -ms-transform: @transform;
            transform: @transform;
}

.linear-gradient (@darkColor: #000, @lightColor:#fff) {
    background: fade(@darkColor, 100%);
    background: -moz-linear-gradient(45deg, fade(@darkColor, 100%) 0%, fade(@darkColor, 100%) 11%, fade(@lightColor, 100%) 49%, fade(@darkColor, 100%) 100%);
    background: -webkit-linear-gradient(45deg, fade(@darkColor, 100%) 0%, fade(@darkColor, 100%) 11%, fade(@lightColor, 100%) 49%, fade(@darkColor, 100%) 100%);
    background: -o-linear-gradient(45deg, fade(@darkColor, 100%) 0%, fade(@darkColor, 100%) 11%, fade(@lightColor, 100%) 49%, fade(@darkColor, 100%) 100%);
    background: -ms-linear-gradient(45deg, fade(@darkColor, 100%) 0%, fade(@darkColor, 100%) 11%, fade(@lightColor, 100%) 49%, fade(@darkColor, 100%) 100%);
    background: linear-gradient(45deg, fade(@darkColor, 100%) 0%, fade(@darkColor, 100%) 11%, fade(@lightColor, 100%) 49%, fade(@darkColor, 100%) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=@darkColor, endColorstr=@darkColor, GradientType=1 );
}

#gradient {
  .radial-three-colors(@inner-color: rgba(85,85,85, 1); @middle-color: rgba(68,68,68, 1); @outer-color: rgba(51,51,51, 1)) {
    background: @middle-color;
    background: -webkit-radial-gradient(circle, @inner-color, @middle-color, @outer-color);
    background: radial-gradient(circle, @inner-color, @middle-color, @outer-color);
    background-repeat: no-repeat;
  }
}

.linear-gradient-border-middle (@borderColor:#000) {
    background: @borderColor;
    background: -moz-linear-gradient(left,  rgba(0,0,0,0) 0%, @borderColor 50%, rgba(0,0,0,0) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0,0,0,0)), color-stop(50%,@borderColor), color-stop(100%,rgba(0,0,0,0)));
    background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 0%, @borderColor 50%, rgba(0,0,0,0) 100%);
    background: -o-linear-gradient(left,  rgba(0,0,0,0) 0%, @borderColor 50%, rgba(0,0,0,0) 100%);
    background: -ms-linear-gradient(left,  rgba(0,0,0,0) 0%, @borderColor 50%, rgba(0,0,0,0) 100%);
    background: linear-gradient(to right,  rgba(0,0,0,0) 0%, @borderColor 50%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(0,0,0,0)', endColorstr='@borderColor',GradientType=1 );
}

.linear-gradient-border-left (@borderColor:#000) {
    background: @borderColor;
    background: -moz-linear-gradient(left,  @borderColor 0%, @borderColor 50%, rgba(0,0,0,0) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,@borderColor), color-stop(50%,@borderColor), color-stop(100%,rgba(0,0,0,0)));
    background: -webkit-linear-gradient(left,  @borderColor 0%, @borderColor 50%, rgba(0,0,0,0) 100%);
    background: -o-linear-gradient(left,  @borderColor 0%, @borderColor 50%, rgba(0,0,0,0) 100%);
    background: -ms-linear-gradient(left,  @borderColor 0%, @borderColor 50%, rgba(0,0,0,0) 100%);
    background: linear-gradient(to right,  @borderColor 0%, @borderColor 50%, rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba(0,0,0,0)', endColorstr='@borderColor',GradientType=1 );
}

.horizontal-gradient (@color-one, @color-two) {
    background: @color-one;
    background: -moz-linear-gradient(top, @color-one 0%, @color-two 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, @color-one), color-stop(100%, @color-two));
    background: -webkit-linear-gradient(top, @color-one 0%, @color-two 100%);
    background: -o-linear-gradient(top, @color-one 0%, @color-two 100%);
    background: -ms-linear-gradient(top, @color-one 0%, @color-two 100%);
    background: linear-gradient(to bottom, @color-one 0%, @color-two 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=@color-one, endColorstr=@color-two, GradientType=0 );
}

.font-face (@name, @font-file, @weight: normal, @style: normal) {

    @path: '../fonts/@{theme}/@{font-file}';

    font-family: @name;
    font-weight: @weight;
    font-style: @style;
    src: url('@{path}.eot');
    src: url('@{path}.eot?#iefix') format('embedded-opentype'),
    url('@{path}.woff') format('woff'),
    url('@{path}.ttf') format('truetype');
}

/**
 * Does the actual work: themes a specific element.
 */
.themed(@color) {
    @themed-contrast: contrast(@color, @theme-color-dark, @theme-color-light);
    @background-color: @color;
    @foreground-color: @themed-contrast;
    @border-color: tint(@background-color, 10%);

    background-color: @background-color;
    color: @foreground-color;

    .themed-contrast {
        color: contrast(@color, @theme-color-dark, @theme-color-light);
    }
}

/**
 * Declare a set of normal, active and hover colors to be a theme.
 * Can be used to create a new theme for an element:
 *
 * ```
 * element {
 *     .theme(@element-color, @element-active, @element-hover);
 *     .themed-interactive(); // optional if iteractive element
 * }
 * ```
 */
.theme(@color, @active, @hover) {
    .themed(@color);
    .themed-interactive(@hover, @active);
}


/**
 * If placed on an element that has had a theme() or themed-primary() etc.
 * declared on it; It will style the active/selected and hover/focus states.
 */
.themed-interactive(@active, @hover) {
    @active-color: @active;
    @hover-color: @hover;

    &.themed-interactive {
        cursor: pointer;

        &:hover, &.hover,
        &:focus, &.focus {
            .themed(@hover-color);
        }

        &:active, &.active,
        &.selected {
            .themed(@active-color);
        }
    }
}
