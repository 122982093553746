// http://nicolasgallagher.com/canvas-fix-svg-scaling-in-internet-explorer/
.ae-animation {
    position: relative;

    canvas {
        display: block;
        width: 100%;
        visibility: hidden;
    }

    svg {
        position: absolute;
        left: 0;
        top: 0;
    }
}