.slide-popup {

    ~ .modal-backdrop {
        background-color: @theme-color-dark-maroon;
        opacity: 0.7;
    }

    &.popup-lg {
        margin-top: 35px;
    }

    &.popup-xl {
        margin-top: 35px;
    }

    &.popup-md {
        margin-top: 172px;
    }

    .modal-content {
        box-shadow: 10px 10px 10px fade(black, 50%);

        .modal-body {
            background: fade(@theme-color-dark-maroon, 90%);
            padding: 40px;
            color: @theme-color-light;

            .close {
                position: absolute;
                top: 10px;
                right: 40px;
                color: white;
                opacity: 1;
            }
        }
    }
}

[linked-component] {
    transition: opacity 300ms ease;

    &:hover {
        opacity: 0.85;
    }
}

@bkgs: bkg-1, bkg-2, bkg-3;

.background-overlays(@bkgs; @index) when (@index > 0) {
    .background-overlays(@bkgs; (@index - 1));

    @bkg  : extract(@bkgs, @index);

    .@{bkg}:after {
        background-image: url('../images/themes/@{theme}/@{bkg}.png');
    }
}

.component-bg-image,
.component-bg-targeted-image,
.component-video-player {


    [class*=bkg-] {
        padding: 0;
        position: static;

        @bkg: extract(@bkgs, 1);

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            min-width: 100%;
            min-height: 100%;
            background-image: url('../images/themes/@{theme}/@{bkg}.png');
            background-position: right top;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .background-overlays(@bkgs, length(@bkgs));

}

.component-high-map {

    .theme {

        &-light {
            &.with-pin {
                background-color: @theme-color-orange;
            }
        }

        &-dark {
            &.with-pin {
                background-color: @theme-color-orange;
            }
        }

        &-red {
            &.with-pin {
                border: 2px solid @theme-color-red;
                background-color: @theme-color-orange;
            }
        }
    }

    .map-marker {

        &.with-label {

            .marker-content {
                font-family: @tradeGothic;
            }
        }
    }
}

.component-bullets {

    ul.circles {

        > li:before {
            top: 7px;
        }

        &.large > li {

            &:before {
                top: 10px;
            }

            font-size: @large-bullet-font-size;
            line-height: @large-bullet-line-height;
            font-weight: @large-bullet-font-weight;
        }

        li a {
            color: @theme-color-orange;
            text-decoration: underline;
        
            &:hover {
                color: darken(@theme-color-orange, 10%);
                text-decoration: underline;
            }
        }
    }
}

.component-textblock a {
    color: @theme-color-orange;
    text-decoration: underline;

    &:hover {
        color: darken(@theme-color-orange, 10%);
        text-decoration: underline;
    }
}
