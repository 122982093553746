.component {
    letter-spacing: -1px;
}

.component-textblock {

    h1, h2, h3, h4, h5 {

        &.drop-shadow {
            text-shadow: 7px 7px 7px rgba(0, 0, 0, 0.8);
            filter: progid:DXImageTransform.Microsoft.Shadow(direction=135,strength=4,Color=@color-black);
        }
    }
}

/* =============================================================================
    COMPONENT: List
    ========================================================================== */
.component-bullets {

    > ul {
        padding: 0;

        > li {
            line-height: 1.1;
        }

        .component-list {
            margin-top: unit(15px / @compat-font-size-base, rem);
        }

        &.chevron-sm {
            @chevron-width: 7px;
            @chevron-height: unit(24px / @compat-font-size-base, rem);
            @chevron-indent: 12px;

            padding-left: @chevron-indent * 2;
            list-style: none;

            > li {
                font-size: unit(18px / @compat-font-size-base, rem);
                position: relative;
                margin-bottom: 4px;
                line-height: 1.5;
                font-family: @univers-LT;
                color: inherit;

                span {
                    vertical-align: sub;
                }

                &:before,
                &:after {
                    width: @chevron-width;
                    height: @chevron-height / 2;

                    content: ' ';
                    display: inline-block;
                    background: @theme-color-gold;
                    position: absolute;
                    top: 0;
                    left: -@chevron-indent * 2;
                }

                &:before {
                    transform-origin: 0 0;
                    .transform(skewX(30deg));
                }

                &:after {
                    transform-origin: 100% 100%;
                    top: (@chevron-height / 2) - 0.05;
                    .transform(skewX(-30deg));
                }
            }
        }

        &.chevron-md {
            @chevron-width: 10px;
            @chevron-height: unit(33px / @compat-font-size-base, rem);
            @chevron-indent: 15px;

            padding-left: @chevron-indent * 2;
            list-style: none;

            > li {
                font-size: unit(26px / @compat-font-size-base, rem);
                position: relative;
                margin-bottom: 12px;
                font-family: @fiba;
                color: inherit;

                span {
                    vertical-align: sub;
                }

                &:before,
                &:after {
                    width: @chevron-width;
                    height: @chevron-height / 2;

                    content: ' ';
                    display: inline-block;
                    background: @theme-color-gold;
                    position: absolute;
                    top: 0;
                    left: -@chevron-indent * 2;
                }

                &:before {
                    transform-origin: 0 0;
                    .transform(skewX(30deg));
                }

                &:after {
                    transform-origin: 100% 100%;
                    top: (@chevron-height / 2) - 0.05;
                    .transform(skewX(-30deg));
                }
            }
        }


        &.chevron-lg {
            @chevron-width: 15px;
            @chevron-height: unit(48px / @compat-font-size-base, rem);
            @chevron-indent: 20px;

            padding-left: @chevron-indent * 2;
            list-style: none;

            > li {
                margin-bottom: 22px;
                position: relative;
                color: inherit;
                font-family: @fiba;
                font-size: unit(38px / @compat-font-size-base, rem);

                span {
                    line-height: 1;
                }

                &:before,
                &:after {
                    width: @chevron-width;
                    height: @chevron-height / 2;

                    content: ' ';
                    display: inline-block;
                    background: @theme-color-gold;
                    position: absolute;
                    top: 0;
                    left: -@chevron-indent * 2;
                }

                &:before {
                    transform-origin: 0 0;
                    .transform(skewX(30deg));
                }

                &:after {
                    transform-origin: 100% 100%;
                    top: (@chevron-height / 2) - 0.05;
                    .transform(skewX(-30deg));
                }
            }
        }
    }
}

/* =============================================================================
    COMPONENT: panel
    ========================================================================== */
.component-comp-panel {

    .panel-circle {
        position: relative;
        padding: 50%;
        border-radius: 50%;
        overflow: hidden;

        > .component-list {
            width: 95%;
        }

        > div,
        > img {
            position: absolute;
            top: 50%;
            left: 50%;
            .transform(translateX(-50%) translateY(-50%));
        }

        > div {
            min-width: 2em;
        }
    }

    .panel-curved,
    .panel-curved-transparent {
        padding: 0;
    }

    .panel-linear-fade {
        @color: @theme-color-blue-three;

        background: fade(@color, 100%);
        background: -moz-linear-gradient(left, fade(@color, 0) 0%, fade(@color, 100%) 35%, fade(@color, 100%) 50%, fade(@color, 100%) 65%, fade(@color, 0) 100%);
        background: -webkit-gradient(left top, right top, color-stop(0%, fade(@color, 0)), color-stop(35%, fade(@color, 100%)), color-stop(50%, fade(@color, 100%)), color-stop(65%, fade(@color, 100%)), color-stop(100%, fade(@color, 0)));
        background: -webkit-linear-gradient(left, fade(@color, 0) 0%, fade(@color, 100%) 35%, fade(@color, 100%) 50%, fade(@color, 100%) 65%, fade(@color, 0) 100%);
        background: -o-linear-gradient(left, fade(@color, 0) 0%, fade(@color, 100%) 35%, fade(@color, 100%) 50%, fade(@color, 100%) 65%, fade(@color, 0) 100%);
        background: -ms-linear-gradient(left, fade(@color, 0) 0%, fade(@color, 100%) 35%, fade(@color, 100%) 50%, fade(@color, 100%) 65%, fade(@color, 0) 100%);
        background: linear-gradient(to right, fade(@color, 0) 0%, fade(@color, 100%) 35%, fade(@color, 100%) 50%, fade(@color, 100%) 65%, fade(@color, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=@color, endColorstr=@color, GradientType=1 );
    }

    .panel-horizontal-gradient-one {
        .horizontal-gradient(@theme-color-blue-two, @theme-color-blue-three);
    }

    .panel-horizontal-gradient-two {
        .horizontal-gradient(@theme-color-blue-three, @theme-color-blue-two);
    }
}

/* =============================================================================
    COMPONENT: background image
    ========================================================================== */
.component-bg-image,
.component-bg-targeted-image,
.component-video-player {

    .panel-curved,
    .panel-curved-transparent {
        padding: 0;
        position: static;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 58%;
            min-width: 500px;
            background-image: url('../images/themes/@{theme}/panel-curve.png');
            background-position: right center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .panel-curved-transparent:after {
        background-image: url('../images/themes/@{theme}/panel-curve-transparent.png');
    }
}

/* =============================================================================
    COMPONENT: image
    ========================================================================== */
.component-image {

    // Temporary fix while component
    // hasn't got this fix applied.
    .image-wrapper {
        display: inline-block;
    }

    .image-circle {
        position: relative;
        padding: 50%;

        .image-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            border-radius: 50%;
        }
    }
}

/* =============================================================================
    COMPONENT: image
    ========================================================================== */
.component-slide-info {

    .chapter-number {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;

        color: inherit;
        font-size: 2rem;
        font-weight: bold;
        border-right: 2px solid @theme-color-gold;
        padding-right: 16px;
        display: inline-block;
        line-height: 1.4;

        & + .component-list {
            margin-left: 50px;
        }
    }
}

/* ==========================================================================
     COMPONENT: Table
     ========================================================================== */
.component-table {

    .unstyled {
        table, td, th  {
            border: 0;
            margin-bottom: 0;
        }
    }

    .styled table {
        border: 0;
        border-spacing: 0 15px;
        border-collapse: separate;
        margin-bottom: 0;

        thead,
        tbody,
        tfoot {

            td,
            th {
                border: 0;
                font-size: unit(24px/@compat-font-size-base, rem);
                color: inherit;
                text-transform: uppercase;

                background-color: fade(@theme-color-blue-two, 90%);
                padding: 15px 35px;

                &.highlight {

                    color: inherit;
                    background-color: fade(@color-primary, 90%) !important;
                }
            }
        }

        thead {
            th {
                color: @color-primary;
                font-size: unit(14px/@compat-font-size-base, rem);
                padding: 0 35px;
                background-color: transparent;
            }
        }
    }

    .table-info {
        margin-top: 30px;
        font-size: unit(10px/@compat-font-size-base, rem);
    }
}

/* ==========================================================================
     COMPONENT: Chart
     ========================================================================== */
.component-chart {
        tspan,
        text {
            font-size: 16px !important;
            letter-spacing: 0;
        }
    }

// Legacy CSS this theme needs to look correctly
.editor-content {
    dd,
    dt,
    li,
    li span,
    p {
        min-height: 17px;
    }
}

.component {
    blockquote,
    cite,
    dd,
    dt,
    li,
    p {
        line-height: 1.1;
    }
}

/* ==========================================================================
     COMPONENT: Map
     ========================================================================== */
.component-high-map {

    .map-marker {

        &.with-label {

            .marker-content {
                font-family: @univers-LT;
            }
        }
    }
}
