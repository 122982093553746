/* =============================================================================
    Web fonts
    ========================================================================== */
@font-rubik: 'Rubik', helvetica, arial, sans-serif;

@font-face {
    .font-face('Rubik', 'Rubik-Bold', 600);
}

@font-face {
    .font-face('Rubik', 'Rubik-Regular');
}

@font-face {
    .font-face('Rubik', 'Rubik-Light', 200);
}
