/* =============================================================================
    Web fonts
    ========================================================================== */
@font-din: 'DINProRegular', Helvetica, Arial, sans-serif;
@font-din-bold: 'DINProBold', Helvetica, Arial, sans-serif;

@font-face {
    .font-face('DINProRegular', 'DINProRegular/DINProRegular');
}

@font-face {
    .font-face('DINProBold', 'DINProBold/DINProBold');
}
