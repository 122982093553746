
@bliss: 'Bliss', helvetica, arial, sans-serif;

@font-face {
    .font-face('Bliss', 'bliss2', normal, normal);
}

@font-face {
    .font-face('Bliss', 'bliss2-italic', normal, italic);
}

@font-face {
    .font-face('Bliss', 'bliss2-bold', bold, normal);
}

@font-face {
    .font-face('Bliss', 'bliss2-boldItalic', bold, italic);
}

@font-face {
    .font-face('Bliss', 'bliss2-light', 300, normal);
}
