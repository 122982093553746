/* =============================================================================
    Web fonts
    ========================================================================== */
@helvetica-neue: 'Helvetica neue', helvetica, arial, sans-serif;

@font-face {
    font-family: 'Helvetica neue';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/helvetica_neue.eot');
    src: url('../fonts/helvetica_neue.eot?#iefix') format('embedded-opentype'),
    url('../fonts/helvetica_neue.woff') format('woff'),
    url('../fonts/helvetica_neue.ttf') format('truetype');
}

@font-face {
    font-family: 'Helvetica neue';
    font-weight: 400;
    font-style: normal;
    src: url('../fonts/helvetica_neue.eot');
    src: url('../fonts/helvetica_neue.eot?#iefix') format('embedded-opentype'),
    url('../fonts/helvetica_neue.woff') format('woff'),
    url('../fonts/helvetica_neue.ttf') format('truetype');
}

@font-face {
    font-family: 'Helvetica neue';
    font-weight: 500;
    font-style: normal;
    src: url('../fonts/helvetica_neue_med.eot');
    src: url('../fonts/helvetica_neue_med.eot?#iefix') format('embedded-opentype'),
    url('../fonts/helvetica_neue_med.woff') format('woff'),
    url('../fonts/helvetica_neue_med.ttf') format('truetype');
}

@font-face {
    font-family: 'Helvetica neue';
    font-weight: bold;
    font-style: normal;
    src: url('../fonts/helvetica_neue_bold.eot');
    src: url('../fonts/helvetica_neue_bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/helvetica_neue_bold.woff') format('woff'),
    url('../fonts/helvetica_neue_bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Helvetica neue';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/helvetica_neue_bold.eot');
    src: url('../fonts/helvetica_neue_bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/helvetica_neue_bold.woff') format('woff'),
    url('../fonts/helvetica_neue_bold.ttf') format('truetype');
}
