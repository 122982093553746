@font-efl:'EFL', Helvetica, Arial, sans-serif;
@font-efl-headline:'EFL Headline', Helvetica, Arial, sans-serif;

@font-face {
    .font-face('EFL Headline', 'EFLHeadlineWeb/EFLHeadlineWeb-Regular');
}

@font-face {
    .font-face('EFL', 'EFLWeb/EFLWeb-Regular');
}

@font-face {
    .font-face('EFL', 'EFLWeb/EFLWeb-Bold', bold);
}

@font-face {
    .font-face('EFL', 'EFLWeb/EFLWeb-BoldItalic', bold, italic);
}

@font-face {
    .font-face('EFL', 'EFLWeb/EFLWeb-Italic', normal, italic);
}

@font-face {
    .font-face('EFL', 'EFLWeb/EFLWeb-Light', 200);
}

@font-face {
    .font-face('EFL', 'EFLWeb/EFLWeb-LightItalic', 200, italic);
}
