@fs-sinclair: 'FS Sinclair', arial, sans-serif;

/*
/* FS Sinclair
****/
@font-face {
  font-family: 'FS Sinclair';
  font-weight: 400;
  src: url('../fonts/capital-one-cup/FSSinclair-Regular.eot');
  src: url('../fonts/capital-one-cup/FSSinclair-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/capital-one-cup/FSSinclair-Regular.woff2') format('woff2'),
  url('../fonts/capital-one-cup/FSSinclair-Regular.woff') format('woff'),
  url('../fonts/capital-one-cup/FSSinclair-Regular.ttf') format('truetype'),
  url('../fonts/capital-one-cup/FSSinclair-Regular.svg#fs_sinclair_regular') format('svg');
}
@font-face {
  font-family: 'FS Sinclair';
  font-weight: normal;
  src: url('../fonts/capital-one-cup/FSSinclair-Regular.eot');
  src: url('../fonts/capital-one-cup/FSSinclair-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/capital-one-cup/FSSinclair-Regular.woff2') format('woff2'),
  url('../fonts/capital-one-cup/FSSinclair-Regular.woff') format('woff'),
  url('../fonts/capital-one-cup/FSSinclair-Regular.ttf') format('truetype'),
  url('../fonts/capital-one-cup/FSSinclair-Regular.svg#fs_sinclair_regular') format('svg');
}
@font-face {
  font-family: 'FS Sinclair';
  font-weight: 700;
  src: url('../fonts/capital-one-cup/FSSinclair-Bold.eot');
  src: url('../fonts/capital-one-cup/FSSinclair-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/capital-one-cup/FSSinclair-Bold.woff2') format('woff2'),
  url('../fonts/capital-one-cup/FSSinclair-Bold.woff') format('woff'),
  url('../fonts/capital-one-cup/FSSinclair-Bold.ttf') format('truetype'),
  url('../fonts/capital-one-cup/FSSinclair-Bold.svg#fs_sinclair_bold') format('svg');
}
@font-face {
  font-family: 'FS Sinclair';
  font-weight: bold;
  src: url('../fonts/capital-one-cup/FSSinclair-Bold.eot');
  src: url('../fonts/capital-one-cup/FSSinclair-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/capital-one-cup/FSSinclair-Bold.woff2') format('woff2'),
  url('../fonts/capital-one-cup/FSSinclair-Bold.woff') format('woff'),
  url('../fonts/capital-one-cup/FSSinclair-Bold.ttf') format('truetype'),
  url('../fonts/capital-one-cup/FSSinclair-Bold.svg#fs_sinclair_bold') format('svg');
}
