// Theme alias
@theme: football-league;

.theme-@{theme} {
    @import '../../main-theme.less';

    @import '_fonts.less';
    @import '_variables.less';
    @import '_palette.less';

    @import '_theme.less';
    @import '_theme.component.less';
}
