.component-chart {
    margin-bottom: 20px;

    .highcharts-container {
        height: auto !important;
    }

    .client-phantomjs & {
        svg {
            display: block;
        }
    }
}
