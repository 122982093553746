// Theme alias
@theme: randa;

.theme-@{theme} {
    @import '../../main-theme.less';

    @import '_fonts.less';
    @import '_variables.less';
    @import '_palette.less';

    @import '_theme.less';
    @import '_theme.component.less';
    @import '_theme.overrides.less';
}
