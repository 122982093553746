.component-grid {

    .col-xs-push {

        &-0-5 {
            margin-left: 4.1665%;
        }

        &-1 {
            margin-left: 8.33333333%;
            left: 0;
        }

        &-1-5 {
            margin-left: 12.5%;
        }

        &-2 {
            margin-left: 16.66666667%;
            left: 0;
        }

        &-2-5 {
            margin-left: 20.8345%;
        }

        &-3 {
            margin-left: 25%;
            left: 0;
        }

        &-3-5 {
            margin-left: 29.1665%;
        }

        &-4 {
            margin-left: 33.33333333%;
            left: 0;
        }

        &-4-5 {
            margin-left: 37.5%;
        }

        &-5 {
            margin-left: 41.66666667%;
            left: 0;
        }

        &-5-5 {
            margin-left: 45.833%;
        }
    }

    .left-align {

        div[class*=-push-] {
            margin-left: 0;
        }
    }
}
