@prelo: 'Prelo', arial, sans-serif;

@font-face {
    .font-face('Prelo', 'prelo-book-webfont', medium);
}

@font-face {
    .font-face('Prelo', 'prelo-semibold-webfont', bold);
}

@font-face {
    .font-face('Prelo', 'prelo-light-webfont', 300);
}

@font-face {
    .font-face('Prelo', 'prelo-extralight-webfont', 200);
}
