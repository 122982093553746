@image-marker-color: @color-white;
@image-marker-hover-color: darken(@image-marker-color, 10%);
@image-marker-active-color: darken(@image-marker-color, 20%);

@image-marker-shadow: 0 4px 6px fade(@color-black, 50%);
@image-marker-size: 20px;
@image-marker-radius: 50%;
@image-marker-border: 0;

.image-markers-wrapper {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;

    .component-image-marker {
        position: absolute;
        display: inline-block;
        width: @image-marker-size;
        height: @image-marker-size;
        border: @image-marker-border;
        border-radius: @image-marker-radius;
        box-shadow: @image-marker-shadow;

        .btn-add-after, .btn-delete, .btn-copy {
            display: none !important;
        }

        [linked-component] {
            height: 100%;

            &:hover [image-marker] {
              background: @image-marker-hover-color;
            }
        }

        .active [image-marker] {
            background-color: @image-marker-active-color;
        }

        [image-marker] {
            width: 100%;
            height: 100%;
            border-radius: @image-marker-radius;
            background: @image-marker-color;
            transition: all 300ms ease;

            .marker {
                height: 100%;
            }
        }
    }
}
