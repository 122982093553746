.timeline-event-options {

    .dropdown {
        float: left;
    }

    .dropdown-menu button {
        border-right: white 35px solid;
    }

    .width-option {
        width: 120px;
    }
}

.component-timeline {
    padding: 20px;

    @table-height:              150px;
    @table-top-height:          55px;
    @table-viewport-width:      96%;

    @cell-margins:              7px;
    @cell-min-width:            30px;

    @year-width:                130px;

    @controls-width:            30px;
    @controls-gradient-width:   20px;

    // Remove default editor outlines
    .editor.edit-mode .component-list & {

        td, tr {
            outline: 0;
        }
    }

    .timeline-wrap {
        position: relative;
    }

    .timeline-gradients {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+99 */
        background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 */
    }

    [class*=timeline-controls] {
        position: absolute;
        top: @table-top-height;
        bottom: 0;
        width: @controls-width;

        button {
            height: 100%;
            font-size: 1rem;
            color: @color-primary;
            background: transparent;

            &:hover {
                color: fade(@color-primary, 70%);
            }

            &:active {
                background: transparent;
                .transform(scale(1.15));
                .box-shadow(none);
            }

            &[disabled] {
                color: @color-neutral;
                background: transparent;
                .transform(scale(1));
            }
        }
    }

    .timeline-controls-left {
        left: 0;
    }

    .timeline-controls-right {
        right: 0;
    }

    .timeline-controls-gradient {
        display: block;
        position: absolute;
        top: -@table-top-height;
        bottom: 0;
        left: -@controls-gradient-width;
        width: @controls-gradient-width;

        .timeline-gradients();

        .transform(rotate(180deg));
    }

    .timeline-viewport {
        display: block;
        overflow: hidden;
        margin: 0 @controls-width 0 (@controls-width + @year-width);
        padding: 1px;

        table {
            position: relative;
            height: @table-height;
        }
    }

    .timeline-table-top {
        // Table TOP row
        .timeline-placeholder {
            height: @table-top-height;
        }
    }

    .timeline-table-bottom {

        .timeline-placeholder:after {
            content: ' ';
            display: block;
            height: 100%;
            margin: 0 @cell-margins;
            background: fade(@color-primary, 20%);
        }
    }

    .timeline-month {
        min-width: @cell-min-width;
        height: @table-top-height;

        > .wrapper {
            margin: 0 @cell-margins @cell-margins @cell-margins;
            border-bottom: 5px solid @color-primary;

            p {
                color: @color-neutral;
            }

            &:after {
                content: '';
                display: block;
                width: 2px;
                height: 10px;
                margin: 15px auto 0 auto;
                background-color: @color-primary;
            }
        }
    }

    // Table BOTTOM row
    .timeline-year,
    .timeline-year-label {
        color: @color-primary;
        font-weight: bold;
        font-size: 2rem;
        width: @year-width;

        > .wrapper {
            width: @year-width;
        }
    }

    .timeline-year-label {
        position: absolute;
        top: 0;
        bottom: 0;
        left: @controls-width;
        background: white;

        > .wrapper {
            padding-top: @table-top-height;
            height: 100%;

            p {
                text-align: center;
                position: relative;
                top: 50%;

                .transform(translateY(-50%));
            }
        }

        .timeline-year-gradient {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: -@controls-gradient-width;
            width: @controls-gradient-width;

            .timeline-gradients();
        }
    }

    .timeline-event {
        vertical-align: top;
        height: 100%;

        > .wrapper {
            position: relative;
            min-width: @cell-min-width;
            margin: 0 @cell-margins;
            background: fade(@color-primary, 40%);
            height: 100%;

            > .component-list {
                padding: 10px;
            }
        }
    }
}
