
.country-detail-config-modal .modal-dialog.modal-lg {
    min-width: 600px;
    width: 30%;
    margin: 3% auto;
}

.slide, .slide-container {

    .marginals {
        font-size: 12px;

        top: @slide-padding-top;
        right: @slide-padding-right;
        left: @slide-padding-left;

        &.header {
            top: 40px;
        }

        &-chapter {
            position: relative;

            &-number {
                visibility: hidden;
            }

            &-name {
                height: 0;
                overflow: hidden;
                display: block;

                &:before {
                    content: 'AS Roma';
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-weight: bold;
                    white-space: nowrap;
                }

                &:after {
                    content: '2020';
                    display: inline-block;
                    position: absolute;
                    top: 14px;
                    left: 0;
                    white-space: nowrap;
                }
            }
        }

        &-date {
            margin-left: 380px;

            &:before {
                content: '';
                display: none;
            }
        }

        &-slide {
            height: 0;
            overflow: hidden;
            display: block;

            &:before {
                content: '';
                opacity: .8;
                position: absolute;
                top: 15px;
                right: 0;
                width: 66px;
                height: 90px;
                display: inline-block;
                background: transparent url('../images/themes/@{theme}/badge.png') no-repeat;
                background-size: contain;
            }
        }
    }
}

.txt-dark.slide, .txt-dark.slide-container {
    .marginals-slide:before {
        background-image: url('../images/themes/@{theme}/badge-dark.png');
    }
}