/* =============================================================================
    Web fonts
    ========================================================================== */

    @fjalla: 'fjalla_oneregular', arial, sans-serif;
    @raleway: 'raleway', arial, sans-serif;
    @lineto-circular: 'lineto-circular', arial, sans-serif;


@font-face {
    font-family: 'fjalla_oneregular';
    src: url('../fonts/fresqo/fjalla/fjallaone-regular-webfont.eot');
    src: url('../fonts/fresqo/fjalla/fjallaone-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/fresqo/fjalla/fjallaone-regular-webfont.woff2') format('woff2'),
         url('../fonts/fresqo/fjalla/fjallaone-regular-webfont.woff') format('woff'),
         url('../fonts/fresqo/fjalla/fjallaone-regular-webfont.ttf') format('truetype'),
         url('../fonts/fresqo/fjalla/fjallaone-regular-webfont.svg#fjalla_oneregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'raleway';
    src: url('../fonts/fresqo/raleway/raleway-bold-webfont.eot');
    src: url('../fonts/fresqo/raleway/raleway-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/fresqo/raleway/raleway-bold-webfont.woff2') format('woff2'),
         url('../fonts/fresqo/raleway/raleway-bold-webfont.woff') format('woff'),
         url('../fonts/fresqo/raleway/raleway-bold-webfont.ttf') format('truetype'),
         url('../fonts/fresqo/raleway/raleway-bold-webfont.svg#ralewaybold') format('svg');
    font-weight: bold;
}

@font-face {
    font-family: 'raleway';
    src: url('../fonts/fresqo/raleway/raleway-medium-webfont.eot');
    src: url('../fonts/fresqo/raleway/raleway-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/fresqo/raleway/raleway-medium-webfont.woff2') format('woff2'),
         url('../fonts/fresqo/raleway/raleway-medium-webfont.woff') format('woff'),
         url('../fonts/fresqo/raleway/raleway-medium-webfont.ttf') format('truetype'),
         url('../fonts/fresqo/raleway/raleway-medium-webfont.svg#ralewaymedium') format('svg');
    font-weight: normal;
}

@font-face {
    font-family: 'lineto-circular';
    src: url('../fonts/fresqo/lineto-circular/lineto-circular-book.eot');
    src: url('../fonts/fresqo/lineto-circular/lineto-circular-book.eot?#iefix') format('embedded-opentype'),
         url('../fonts/fresqo/lineto-circular/lineto-circular-book.woff') format('woff'),
         url('../fonts/fresqo/lineto-circular/lineto-circular-book.ttf') format('truetype'),
         url('../fonts/fresqo/lineto-circular/lineto-circular-book.svg#circular') format('svg');
    font-weight: normal;
}

@font-face {
    font-family: 'lineto-circular';
    src: url('../fonts/fresqo/lineto-circular/lineto-circular-bookItalic.eot');
    src: url('../fonts/fresqo/lineto-circular/lineto-circular-bookItalic.eot?#iefix') format('embedded-opentype'),
         url('../fonts/fresqo/lineto-circular/lineto-circular-bookItalic.woff') format('woff'),
         url('../fonts/fresqo/lineto-circular/lineto-circular-bookItalic.ttf') format('truetype'),
         url('../fonts/fresqo/lineto-circular/lineto-circular-bookItalic.svg#circular') format('svg');
    font-style: italic;
}
