/* =============================================================================
    THEME: overrides
   ========================================================================== */

.preloader {

    &-message {

        &-button {
            color: @color-black;
            position: relative;
            z-index: 10;
        }
    }

    &.mask {

        background: @color-white;

        .preloader-percent {

            background: @color-white;
            min-height: 300px;
            min-width: 300px;

            p {
                color: @color-black;
            }
        }

        .preloader-image {
            background-image: url('../images/themes/fresqo/preloader-image-mask.png');
        }
    }
}
