.txt-light .component-bullets {
    > .dash,
    > .dash-small {
        > li:before {
            background-color: @theme-color-light;
        }
    }
}

.component-bullets {

    @dash-indent: 22px;
    @dash-height: 2px;
    @dash-right-padding: 12px;

    > ul > li {
        font-family: @font-randa-text;
        font-size: @bullet-font-size;
    }

    > .dash {
        padding-left: @dash-indent;

        > li:before {
            content: ' ';
            position: absolute;
            top: 15px;
            left: -@dash-indent;
            width: @dash-indent - @dash-right-padding;
            height: @dash-height;
            background-color: @theme-color-dark;
        }
    }

    > .dash-small {
        .dash();

        > li {
            font-size: 1.688rem;
            line-height: 1.3;
        }
    }

    > .dash-yellow {
        .dash();

        > li:before {
            background-color: @theme-color-yellow-label !important;
        }
    }

    &.component-direction-rtl {

        > .dash,
        > .dash-yellow,
        > .dash-small {
            padding-left: 0;
            padding-right: @dash-indent;

            > li:before {
                left: 0;
                right: -@dash-indent;
            }
        }
    }
}

.component-high-map {

    .map-marker {

        &.with-label {

            .marker-content {
                font-family: @font-randa-text;
            }
        }
    }
}
