.component {

    &-bullets > ul {

        &.small,
        &.large {

            > li {
                font-family: @textblock-font-family-paragraph;
                font-style: @textblock-font-style-paragraph;
                font-weight:@textblock-font-weight-paragraph;
                margin-bottom: 20px;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    display: inline-block;
                }
            }
        }

        &.large > li {

            padding-left: 40px;
            font-size: 27px;
            line-height: 1.25;
            text-transform: uppercase;

            &:before {
                width: 20px;
                height: 29px;
                top: 2px;
                background: url('@{theme-image-path}/arrow-big.png') 0 0 no-repeat;
            }
        }

        &.small > li {

            padding-left: 27px;
            font-size: 21px;
            line-height: 1.42;
            text-transform: none;

            &:before {
                width: 12px;
                height: 18px;
                top: 7px;
                background: url('@{theme-image-path}/arrow.png') 0 0 no-repeat;
            }
        }
    }

    &-comp-panel {

        .panel-wrapper {
            padding: 15px;
            position: relative;

            &:before,
            &:after {
                content: '';
                background-color: inherit;
                position: absolute;
                top: 0;
                width: 30%;
                height: 100%;
                z-index: -1;
            }

            &:before {
                left: -5%;
                .transform(skew(-20deg));
            }

            &:after {
                right: -5%;
                .transform(skew(-20deg));
            }
        }
    }
}

// Leaving dynamic SASS loop
// as we might have new backgrounds
// in the near future
@bkgs: bkg-1,;

.background-overlays(@bkgs; @index) when (@index > 0) {
    .background-overlays(@bkgs; (@index - 1));

    @bkg: extract(@bkgs, @index);

    .@{bkg}:after {
        background-image: url('@{theme-image-path}/@{bkg}.png');
    }
}

.component-bg-targeted-image,
.component-video-player {

    [class*=bkg-] {
        padding: 0;
        position: static;

        @bkg: extract(@bkgs, 1);

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            min-width: 100%;
            min-height: 100%;
            background-position: -48% top;
            background-repeat: no-repeat;
        }
    }

    .background-overlays(@bkgs, length(@bkgs));

}
