// H1
@textblock-font-size-h1: 36px;
@textblock-font-family-h1: inherit;
@textblock-font-style-h1: normal;
@textblock-font-weight-h1: inherit;
@textblock-line-height-h1: 1.1;
@textblock-letter-spacing-h1: inherit;
@textblock-color-h1: inherit;
@textblock-text-transform-h1: inherit;
@textblock-margin-bottom-h1: 30px;
// H2
@textblock-font-size-h2: 28px;
@textblock-font-family-h2: inherit;
@textblock-font-style-h2: normal;
@textblock-font-weight-h2: inherit;
@textblock-line-height-h2: 1.2;
@textblock-letter-spacing-h2: inherit;
@textblock-color-h2: inherit;
@textblock-text-transform-h2: inherit;
@textblock-margin-bottom-h2: 30px;
// H3
@textblock-font-size-h3: 24px;
@textblock-font-family-h3: inherit;
@textblock-font-style-h3: normal;
@textblock-font-weight-h3: inherit;
@textblock-line-height-h3: 1.25;
@textblock-color-h3: inherit;
@textblock-letter-spacing-h3: inherit;
@textblock-text-transform-h3: inherit;
@textblock-margin-bottom-h3: 24px;
// H4
@textblock-font-size-h4: 20px;
@textblock-font-family-h4: inherit;
@textblock-font-style-h4: normal;
@textblock-font-weight-h4: inherit;
@textblock-line-height-h4: 1.3;
@textblock-color-h4: inherit;
@textblock-letter-spacing-h4: inherit;
@textblock-text-transform-h4: inherit;
@textblock-margin-bottom-h4: 24px;
// H5
@textblock-font-size-h5: 18px;
@textblock-font-family-h5: inherit;
@textblock-font-style-h5: normal;
@textblock-font-weight-h5: inherit;
@textblock-line-height-h5: 1.3;
@textblock-color-h5: inherit;
@textblock-letter-spacing-h5: inherit;
@textblock-text-transform-h5: inherit;
@textblock-margin-bottom-h5: 24px;
// H6
@textblock-font-size-h6: 16px;
@textblock-font-family-h6: inherit;
@textblock-font-style-h6: normal;
@textblock-font-weight-h6: inherit;
@textblock-line-height-h6: 1.5;
@textblock-color-h6: inherit;
@textblock-letter-spacing-h6: inherit;
@textblock-text-transform-h6: inherit;
@textblock-margin-bottom-h6: 24px;
// P
@textblock-font-size-paragraph: 14px;
@textblock-font-family-paragraph: inherit;
@textblock-font-style-paragraph: normal;
@textblock-font-weight-paragraph: inherit;
@textblock-line-height-paragraph: 1.4;
@textblock-color-paragraph: inherit;
@textblock-letter-spacing-paragraph: inherit;
@textblock-text-transform-paragraph: inherit;
@textblock-margin-bottom-paragraph: 24px;
// Blockquote
@textblock-font-size-blockquote: 18px;
@textblock-font-family-blockquote: inherit;
@textblock-font-style-blockquote: normal;
@textblock-font-weight-blockquote: inherit;
@textblock-line-height-blockquote: 1.4;
@textblock-color-blockquote: inherit;
@textblock-letter-spacing-blockquote: inherit;
@textblock-text-transform-blockquote: inherit;
@textblock-margin-bottom-blockquote: 24px;
// Cite
@textblock-font-size-cite: 12px;
@textblock-font-family-cite: inherit;
@textblock-font-style-cite: normal;
@textblock-font-weight-cite: inherit;
@textblock-line-height-cite: 1.25;
@textblock-color-cite: inherit;
@textblock-letter-spacing-cite: inherit;
@textblock-text-transform-cite: inherit;
@textblock-margin-bottom-cite: 12px;

.header-variant(@variant) {

    @fsz: "textblock-font-size-@{variant}";
    @fff: "textblock-font-family-@{variant}";
    @fst: "textblock-font-style-@{variant}";
    @fw: "textblock-font-weight-@{variant}";
    @flh: "textblock-line-height-@{variant}";
    @fcl: "textblock-color-@{variant}";
    @fls: "textblock-letter-spacing-@{variant}";
    @ftt: "textblock-text-transform-@{variant}";
    @fmb: "textblock-margin-bottom-@{variant}";

    font-size: @@fsz;
    font-family: @@fff;
    font-style: @@fst;
    font-weight: @@fw;
    line-height: @@flh;
    color: @@fcl;
    letter-spacing: @@fls;
    text-transform: @@ftt;
    margin-bottom: @@fmb;
}

.component-textblock {

    > * {
        margin: 0;
    }

    [textblock] {
        text-align: initial;

        &.align-left {
            text-align: left;
        }
        &.align-center {
            text-align: center;
        }
        &.align-right {
            text-align: right;
        }
        &.align-justify {
            text-align: justify;
        }
    }

    p {
        font-size: @textblock-font-size-paragraph;
        font-family: @textblock-font-family-paragraph;
        font-style: @textblock-font-style-paragraph;
        font-weight: @textblock-font-weight-paragraph;
        line-height: @textblock-line-height-paragraph;
        color: @textblock-color-paragraph;
        letter-spacing: @textblock-letter-spacing-paragraph;
        text-transform: @textblock-text-transform-paragraph;
        margin-bottom: @textblock-margin-bottom-paragraph;
    }

    blockquote {
        font-size: @textblock-font-size-blockquote;
        font-family: @textblock-font-family-blockquote;
        font-style: @textblock-font-style-blockquote;
        font-weight: @textblock-font-weight-blockquote;
        line-height: @textblock-line-height-blockquote;
        color: @textblock-color-blockquote;
        letter-spacing: @textblock-letter-spacing-blockquote;
        text-transform: @textblock-text-transform-blockquote;
        margin-bottom: @textblock-margin-bottom-blockquote;
    }

    cite {
        font-size: @textblock-font-size-cite;
        font-family: @textblock-font-family-cite;
        font-style: @textblock-font-style-cite;
        font-weight: @textblock-font-weight-cite;
        line-height: @textblock-line-height-cite;
        color: @textblock-color-cite;
        letter-spacing: @textblock-letter-spacing-cite;
        text-transform: @textblock-text-transform-cite;
        margin-bottom: @textblock-margin-bottom-cite;
    }

    // H1 to H6
    @iterations: 6;

    .headers-loop (@i) when (@i > 0) {
        @variant: "h@{i}";
        h@{i} {
            .header-variant(@variant);
        }
        .headers-loop(@i - 1);
    }

    .headers-loop(@iterations);
}
