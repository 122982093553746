.txt-light .component-bullets .dash li:before {
    background-color: @theme-color-light;
}

.component-bullets {

    @dash-indent: 22px;
    @dash-height: 2px;
    @dash-right-padding: 12px;

    > ul > li {
        font-size: @bullet-font-size;
    }

    > .dash {
        padding-left: @dash-indent;

        > li:before {
            content: ' ';
            position: absolute;
            top: 15px;
            left: -@dash-indent;
            width: @dash-indent - @dash-right-padding;
            height: @dash-height;
            background-color: @theme-color-dark;
        }
    }

    > .dash-yellow {
        .dash();

        > li:before {
            background-color: @theme-color-yellow-label !important;
        }
    }

    &.component-direction-rtl {

        > .dash,
        > .dash-yellow {
            padding-left: 0;
            padding-right: @dash-indent;

            > li:before {
                left: 0;
                right: -@dash-indent;
            }
        }
    }
}

.component-high-map {

    .map-marker {

        &.with-label {

            .marker-content {
                font-family: @font-helvetica;
            }
        }
    }
}
