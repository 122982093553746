.client-message-editor {

    .toolbar {
        margin-bottom: 10px;
    }

    [contenteditable] {
        background-color: @color-white;
        padding: 10px;
        outline: none;
        min-height: 40px;
        font-family: @theme-font-family;
    }
}

.targeted-message-wrapper {
    font-family: @theme-font-family;

    &.client-message-glow {
        text-shadow: 0 0 15px #ffffff;
    }
}
