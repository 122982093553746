.component-video-player {

    .video-player {

        overflow: visible;

        video.fullscreen {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            z-index: -100;
            background-size: cover;

            .transform(translate(-50%, -50%));
        }

        .video-controls .controls-panel  {

            input[type=range]::-webkit-slider-thumb {
                background: @color-primary;

            }
        }

        .video-poster {
            z-index: 1;
        }
    }
}

.video-controls {
    bottom: 20px;
}

.video-controls.fade.in {
    z-index: 1000;
}
