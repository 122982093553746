.component-list {

    .component-floating-box {
        position: absolute;
        height: auto;
        left: 50%;
        top: 50%;
        .box-shadow(0 3px 10px 2px fade(black, 17%));

        &.before-show {
            display: block;
            visibility: hidden;
        }

        .floating-box {
            padding: 10px;
            display: block;
            background-color: @color-white;

            .floating-content {
                position: relative;

                .component-list {

                    &:before {
                        line-height: 2em;
                    }
                }
            }
        }
    }
}
