@primary-font: 'Bebas Neue', helvetica, arial, sans-serif;
@secondary-font: 'Gotham', helvetica, arial, sans-serif;

@font-face {
    .font-face('Bebas Neue', 'BebasNeue');
}

@font-face {
    .font-face('Gotham', 'Gotham-Bold', bold);
}

@font-face {
    .font-face('Gotham', 'Gotham-BoldItalic', bold, italic);
}

@font-face {
    .font-face('Gotham', 'Gotham-Book', 300);
}

@font-face {
    .font-face('Gotham', 'Gotham-BookItalic', 300, italic);
}

