.component-high-map {

    .map {
        position: relative;

        &-container {
            position: relative;
            width: 100%;
            height: 620px;

            &-overlay {
                display: none;
            }
        }

        &-label {
            z-index: 99999 !important;
        }

        &.zoom-mode {

            .map-container {

                overflow: hidden;

                &-overlay {

                    background: @color-white;
                    color: @color-black;
                    border: 1px solid darken(white, 10%);
                    width: 50%;
                    max-width: 760px;
                    opacity: .8;
                    position: absolute;
                    top: 20px;
                    left: 50%;
                    .transform(translateX(-50%));
                    display: block;

                    .row {

                        padding: 15px;
                        text-align: center;

                        div:first-child {
                            padding: 20px;
                        }

                        div:last-child {
                            border-left: 1px solid lighten(@color-gray, 40%);
                            padding: 20px;
                            font-size: 14px;
                        }
                    }

                    @media screen and (max-width: @compat-screen-sm) {
                        width: 100%;
                    }
                }
            }
        }
    }

    .theme {

        &-light {
            &.with-pin {
                border: 2px solid @color-white;
                background-color: #5d5d5d;
            }
        }

        &-dark {
            &.with-pin {
                border: 2px solid #424242;
                background-color: #cecece;
            }
        }
    }

    .map-marker {
        position: relative;
        cursor: pointer;

        img {
            width: 20px;
        }

        .marker-content {
            display: none;
            position: absolute;
        }

        &.with-pin {
            position: relative;
            border-radius: 100%;
            top: -0.4rem;
            left: -0.4rem;
            height: 0.8rem;
            width: 0.8rem;
        }

        &.with-label {
            margin-top: 0;

            .marker-content {
                display: block;
                left: 0.5rem;
                bottom: 0.8rem;
                color: @color-white;
                padding: 8px;
                border: 1px solid @color-black;
                background-color: fade(@color-white, 80%);
                font-family: @theme-font-family;
                font-size: 0.8rem;

                .value {
                    display: block;
                    font-size: 1.1rem;
                    font-weight: bold;
                }
            }
        }
    }
}

.country-detail-config-modal {

    .high-map-modal {
        &-body {
            padding: 50px;
        }
    }

    .modal-content {
        overflow: visible;
    }
}

.slide {

    .editor-back-layer {

        .component-high-map {

            top: 0;

            .map,
            .map-container {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        &.edit-mode {

            .component-high-map {
                top: 34px;
            }
        }
    }
}
