
@bkgs: bkg-1, bkg-2;

.background-overlays(@bkgs; @index) when (@index > 0) {
    .background-overlays(@bkgs; (@index - 1));

    @bkg  : extract(@bkgs, @index);

    .@{bkg}:after {
        background-image: url('../images/themes/@{theme}/@{bkg}.png');
    }
}

.component-bg-image,
.component-bg-targeted-image,
.component-video-player {

    [class*=bkg-] {
        padding: 0;
        position: static;

        @bkg: extract(@bkgs, 1);

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            min-width: 100%;
            min-height: 100%;
            background-image: url('../images/themes/@{theme}/@{bkg}.png');
            background-position: right top;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .background-overlays(@bkgs, length(@bkgs));

}

.component-high-map {

    .theme {

        &-light {
            &.with-pin {
                background-color: @theme-color-gold;
            }
        }

        &-dark {
            &.with-pin {
                background-color: @theme-color-gold;
            }
        }
    }

    .map-marker {

        &.with-label {

            .marker-content {
                font-family: @primary-font;
            }
        }
    }
}

.component-table {

    [table] > table {
        border-spacing: 0 15px;
        border-collapse: separate;
        margin-bottom: 0;

        &, tr, th, td {
            border: 0;
            text-align: center;
            vertical-align: middle;
            color: @color-white;
        }

        tr {
            background-color: fade(black, 70%);
        }

        thead {
            tr > th {
                text-transform: uppercase;
                padding: 2px;
                font-size: 24px;
                line-height: 1.2;
            }
        }

        tbody {
            tr td  {
                border-style: solid;
                border-color: @theme-color-gold;
                border-width: 3px 0;
                font-size: 18px;
                line-height: 1.2;
            }
        }
    }
}
