@gothic: 'Mark', helvetica, arial, sans-serif;
@druk: 'Druk', helvetica, arial, sans-serif;
@tradeGothic: 'Trade Gothic', helvetica, arial, sans-serif;

@font-face {
    .font-face('Mark', 'mark-bold', normal);
}

@font-face {
    .font-face('Druk', 'druk-medium');
}

@font-face {
    .font-face('Druk', 'druk-medium-italic', normal, italic);
}

@font-face {
    .font-face('Trade Gothic', 'trade-gothic');
}

@font-face {
    .font-face('Trade Gothic', 'trade-gothic-bold', bold);
}

@font-face {
    .font-face('Trade Gothic', 'trade-gothic-light', 200);
}
