.targeted-image, .bg-image-container {

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(255,255,255);
        background: rgba(255,255,255, 0.9);
        text-align: center;
        z-index: 1;

        img {
            display: inline-block;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            width: 55px;
            height: 40px;
        }
    }
}

.component-targeted-image {

    .middle {
        text-align: center;
    }

    .right {
        text-align: right;
    }

    img {
        display: inline-block;
        //width: 100%;

        &.placeholder {
            display: none;
        }
    }

    .targeted-image-wrapper {
        position: relative;
        display: inline-block;
        vertical-align: bottom;

        .transition(height 500ms ease-out);
    }

    .overlay-section {
        text-align: initial;

        &.top,
        &.middle,
        &.bottom {
            width: 100%;
            position: absolute;
            left: 0;
        }

        &.top {
            top: 0;
        }

        &.middle {
            top: 50%;
            .transform(translateY(-50%));
        }

        &.bottom {
            bottom: 0;
        }
    }

    .print-image {
        display: none;

        width: 100%;
        height: 100%;

        &.left {
            &-top img {
                left: 0;
                top: 0;
                .transform(translate(0, 0));
            }

            &-middle img {
                left: 0;
                top: 50%;
                .transform(translate(0, -50%));
            }

            &-bottom img {
                left: 0;
                top: auto;
                bottom: 0;
                .transform(translate(0, 0));
            }
        }

        &.center {
            &-top img {
                left: 50%;
                top: 0;
                .transform(translate(-50%, 0));
            }

            &-middle img {
                left: 50%;
                top: 50%;
                .transform(translate(-50%, -50%));
            }

            &-bottom img {
                left: 50%;
                top: auto;
                bottom: 0;
                .transform(translate(-50%, 0));
            }
        }

        &.right {
            &-top img {
                left: auto;
                right: 0;
                top: 0;
                .transform(translate(0, 0));
            }

            &-middle img {
                left: auto;
                right: 0;
                top: 50%;
                .transform(translate(0, -50%));
            }

            &-bottom img {
                left: auto;
                right: 0;
                top: auto;
                bottom: 0;
                .transform(translate(0, 0));
            }
        }
    }
}


