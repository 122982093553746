.component-i-frame {

    iframe {
        border: none;
    }

    .iframe-overlay {
        display: block;
        position: absolute;
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
    }
}

.editor-back-layer {
    .component-i-frame iframe {
        height: 100%;
    }
}

