// Taken from the old set of Bootstrap variables
@compat-screen-xs:                     1024px;
@compat-screen-sm:                     1280px;
@compat-screen-md:                     1440px;
@compat-screen-lg:                     1600px;
@compat-screen-xl:                     1920px;

&.fixed-canvas, &.client-phantomjs {
    // Fix breakpoint to medium (1440px)
    font-size: @compat-font-size-md !important;
}

&.admin-editor-presentation:not(.fixed-canvas) {

    @media (min-width: @compat-screen-xs) {
        font-size: @compat-font-size-xs;
    }

    @media (min-width: @compat-screen-sm) {
        font-size: @compat-font-size-sm;
    }

    @media (min-width: @compat-screen-md) {
        font-size: @compat-font-size-md;
    }

    @media (min-width: @compat-screen-lg) {
        font-size: @compat-font-size-lg;
    }

    body.admin-editor-presentation,
    body.admin-slideshow-presentation {

        @media (min-width: @compat-screen-xs) {
            font-size: @compat-font-size-xs;
        }

        @media (min-width: @compat-screen-sm) {
            font-size: @compat-font-size-sm;
        }

        @media (min-width: @compat-screen-md) {
            font-size: @compat-font-size-md;
        }

        @media (min-width: @compat-screen-lg) {
            font-size: @compat-font-size-lg;
        }
    }
}

