@color-primary: @color-slate;
@color-neutral: @color-light-gray;

// Client brand color
@client-brand-primary: @color-slate;
@sidebar-second-color: 'transparent';

@theme-color-light: @color-white;
@theme-color-dark: @color-black;

@theme-letterbox-color: @color-black;
@theme-gradient-color: @color-black;

@table-highlight-color-dark: @theme-color-light;
@table-highlight-color-light: @table-highlight-color-dark;

// Popup modal
@theme-popup-backdrop-color: @color-black;
@theme-popup-backdrop-opacity: 0.5;
@theme-popup-background-color: @color-white;
@theme-popup-text-color: contrast(@theme-popup-background-color);
@theme-popup-sm: 300px;
@theme-popup-sm-margin: 45px auto;

@theme-popup-md: 600px;
@theme-popup-md-margin: 45px auto;

@theme-popup-lg: 986px;
@theme-popup-lg-margin: 45px auto;

@theme-popup-xl: 1350px;
@theme-popup-xl-margin: 45px auto;

@theme-popup-suite: 800px;
@theme-popup-suite-margin: 45px 45px;

@theme-popup-padding: 30px 50px;

// Old bootstrap value
@grid-gutter-width: 10px;

// Branded image and logo
@theme-image-path: '../images/themes/@{theme}';
@login-image: '';
@nav-logo: '';
@nav-logo-width: 48px;
@nav-logo-height: 100px;

@theme-font-family: 'Avenir next', Arial, Helvetica, sans-serif;
@theme-font-weight: 500;
@theme-font-size: @theme-font-size-base;
@theme-line-height: 1.25;

@marginals-info-separator: '/';

@slide-padding-top: 87px;
@slide-padding-bottom: 15%;
@slide-padding-left: 50px;
@slide-padding-right: 50px;

@theme-zindex-overlay: 520;

// Backward compatibility media query font sizes
@theme-font-size-base:          16px;
@compat-font-size-base:         @theme-font-size-base;
@compat-font-size-xs:           @theme-font-size-base - 4;
@compat-font-size-sm:           @theme-font-size-base - 2;
@compat-font-size-md:           @theme-font-size-base;
@compat-font-size-lg:           @theme-font-size-base + 2;
@compat-font-size-xl:           @theme-font-size-base + 4;

// SVG files
@svg-path:                      "../svg/";
