.component {

    &-textblock {
        cite {
            margin-top: unit(20px/@compat-font-size-base, rem)/2 * -1;
        }

        blockquote {

            @media screen and (min-width: 1366px) {
                font-size: unit(34px/@compat-font-size-base, rem);
            }
        }

        h1 {
            font-size: 5rem;
        }
    }

    &-comp-panel {

        .panel-wrapper {
            padding: 0;
        }

        .gradient-radial {
            #gradient.radial-three-colors(rgba(0, 0, 0, 0.75) 20%;
            rgba(0, 0, 0, 0.5) 60%;
            rgba(0, 0, 0, 0) 85%);
            padding: 20px 60px;
        }

        .corner-braces, .presentation-cover {
            @corner-size: 200px;
            padding: 30px;
            margin-bottom: 30px !important;
            overflow: hidden;
            position: relative;
            display: block;

            &:before, &:after {
                content: "";
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100px;
                height: 100px;
                background: transparent no-repeat top left;
                background-size: contain;
                background-image: data-uri('image/svg+xml;base64', '@{svg-path}tFL_cup-brackets.svg');
            }

            &:after {
                top: auto;
                left: auto;
                bottom: 0;
                right: 0;
                .rotate(180deg); //BOOTSTRAP
            }
        }

        .corner-braces {
            #gradient.radial-three-colors(rgba(0, 0, 0, 0.75) 50%;
                                          rgba(0, 0, 0, 0.6) 75%;
                                          rgba(0, 0, 0, 0.25) 100%);

            &:before, &:after {
                width: 150px;
                height: 150px;
            }
        }

        .shaded {
            background: rgba(0, 0, 0, 0.5);
            padding: 18px;
            margin-bottom: 30px;
        }

    }
}

// Backward compatibility
.component,
.marginals {

    p {
        font-size: inherit !important;
        margin: 0;
    }

    // 480px
    @media screen and (min-width: @compat-screen-xs) {
        font-size: 16px;
    }

    // 1024px
    @media screen and (min-width: @compat-screen-sm) {
        font-size: 18px;
    }

    // 1500px
    @media screen and (min-width: @compat-screen-md) {
        font-size: 20px;
    }

    // 1900px
    @media screen and (min-width: @compat-screen-lg) {
        font-size: 20px;
    }
}
