.txt-dark.slide .marginals {

    &-presentation,
    &-slide-number,
    &-chapter-name {
        color: @color-white;
    }
}

.slide, .slide-container {

    .marginals {

        &.header {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            background-color: fade(@theme-color-dark-blue, 35%);
            border-bottom: 1px solid fade(#ffffff, 35%);
            padding: 26px @slide-padding-right 26px @slide-padding-left;
        }

        &-presentation,
        &-slide-number,
        &-chapter-name {
            color: @theme-color-silver;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 18px;
            line-height: 1;
        }

        &-presentation,
        &-presentation-total-slides,
        &-presentation-slide-index-in-presentation {
            display: inline-block;
            float: right;
        }

        &-presentation-slide-index:after,
        &-presentation-slide-index-in-presentation:after {
            content: '/';
        }

        &-presentation {
            position: absolute;
            top: 26px;
            right: @slide-padding-right;
        }

        &-marginals-presentation-slide-index,
        &-presentation-name,
        &-chapter-number,
        &-slide-name,
        &-slide-count,
        &-slide-number,
        &-slide-number:after {
            display: none;
        }
    }
}
