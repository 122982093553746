.slide-popup {

    &.modal-suite {
        margin: 0;
        position: absolute;
        top: 72px;
        left: 40px;
        bottom: 40px;
        width: 800px;
        box-shadow: 0 5px 50px fade(black, 30%);

        + .modal-backdrop {
            display: none;
        }

        .modal-dialog,
        .modal-content,
        .modal-content > div,
        .modal-body {
            margin: 0;
            width: 100%;
            height: 100%;
        }

        .modal-body,
        .modal-header + .modal-body {
            padding: 20px;
        }
    }
}

.component {

    &-bullets {

        > ul {
            &.dark-small-paragraph,
            &.dark-paragraph,
            &.small-paragraph,
            &.paragraph {
                .list-circles();
            }

            &.dark-small-paragraph,
            &.dark-paragraph {
                > li:before {
                    background-color: @theme-color-dark-blue;
                }
            }

            &.dark-paragraph > li,
            &.paragraph > li {
                font-size: @textblock-font-size-h5;
                font-family: @textblock-font-family-h5;
                font-style: @textblock-font-style-h5;
                font-weight: @textblock-font-weight-h5;
                line-height: @textblock-line-height-h5;
                color: @textblock-color-h5;
                letter-spacing: @textblock-letter-spacing-h5;
                text-transform: @textblock-text-transform-h5;
                margin-bottom: 10px;
            }
        }

        &.component-direction-rtl {

            > ul {
                .list-rtl(true);
            }
        }
    }

    &-table {

        table {

            &, tr > th, tr > td {
                border: 0;
                padding: 30px 20px;
                vertical-align: middle;
            }

            thead th {
                border-bottom: 5px solid @theme-color-light;
            }

            td.highlight {
                color: @theme-color-dark;
            }
        }
    }

    &-high-map {

        .theme {

            &-orange {

                &.with-pin {
                    background-color: fade(@color-white, 50%);
                    border: 2px solid @color-white;

                    .marker-content {
                        color: @theme-color-silver;
                    }
                }
            }
        }

        .map-marker {

            &.with-label {

                .marker-content {
                    font-family: @font-rubik;
                }
            }
        }
    }

    @tabs-theme-border: 1px solid @color-white;
    @tabs-theme-border-radius: 5px;

    &-tabs {

        .tabs-heading-container {

            ul.tabs-heading {

                > li {
                    min-width: 120px;
                    text-align: center;

                    input {
                        width: 100%;
                        text-align: center;
                    }
                }

                .tabs-heading-tab {

                    border: @tabs-theme-border;

                    &:first-child {
                        border-radius: @tabs-theme-border-radius 0 0 @tabs-theme-border-radius;
                        border: @tabs-theme-border;
                    }

                    &:last-child {
                        border-radius: 0 @tabs-theme-border-radius @tabs-theme-border-radius 0;
                    }
                }
            }
        }
    }
}
