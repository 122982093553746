// This is the default theme for Bylder
// Any new themes in the platform will
// extend from it

// Theme alias
@theme: bylder;

.theme-@{theme} {
    @import '../../main-theme.less';

    // Palette
    @import '_palette.less';

    // Variables
    @import '_variables.less';
}
