.slide-popup {
    margin: 0 auto;

    &.popup-sm .modal-dialog {
        width: @theme-popup-sm;
        margin: @theme-popup-sm-margin;

        .modal-footer {

            .popup-controls {
                .dropdown {
                    width: @theme-popup-sm - 100;
                }
            }
        }
    }

    &.popup-md .modal-dialog {
        width: @theme-popup-md;
        margin: @theme-popup-md-margin;

        .modal-footer {

            .popup-controls {
                .dropdown {
                    width: @theme-popup-md - 100;
                }
            }
        }
    }

    &.popup-lg .modal-dialog {
        width: @theme-popup-lg;
        margin: @theme-popup-lg-margin;
    }
    &.popup-xl .modal-dialog {
        width: @theme-popup-xl;
        margin: @theme-popup-xl-margin;
    }

    &.popup-suite .modal-dialog {
        width: @theme-popup-suite;
        margin: @theme-popup-suite-margin;
    }

    .modal-content {
        background-color: transparent;
        overflow: visible;
        border: 0;

        .popup-editor-container {
            transform: translateY(0px);

            .component-trace {

                display: none;

                &.active {
                    display: block;
                    transform: translateY(100%);
                }
            }
        }

        .modal-header + .modal-body {
            padding: @theme-popup-padding;
        }

        .modal-header {
            background-color: @color-lighter-gray;
        }

        .modal-body {
            // Ensure modal footer buttons sit
            // underneath editor options drop downs
            z-index: 1;
            background-color: @color-white;

            button.close {
                position: absolute;
                right: 8px;
                top: 4px;
            }
        }

        .modal-footer {
            background-color: @color-lighter-gray;

            .modal-btn-col {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}
